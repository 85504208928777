import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Title, Paper, Button, FileInput } from "@mantine/core";
import { TextInput, Select } from "react-hook-form-mantine";
import { modals } from "@mantine/modals";
import { IconFileUpload } from "@tabler/icons-react";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useGetTags } from "../../api/tag";
import { useCreateSpecialInvitation } from "../../api/announcements";
import CropImage from "../Events/Customize/CropImage";
import { toBase64 } from "../../services/helper";

function CreateSpecialInvite() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      title: "",
      isVisible: "false",
      eventId,
      targetedTagId: "",
      image: "",
    },
  });

  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    if (newImage) {
      toBase64(newImage).then((base64) => {
        setValue("image", base64);
      });
    } else {
      setValue("image", "");
    }
  }, [newImage, setValue]);

  const { data: tags } = useGetTags({
    eventId,
    type: "people",
  });

  const tagsOptions = [
    { value: "", label: "All attendees" },
    ...(tags ?? []).map((tag) => ({
      value: String(tag.id),
      label: `${tag.title} - (${tag.usageCount} users)`,
    })),
  ];

  //   const openImageModal = (img) => {
  //     modals.open({
  //       title: `${img.name}`,
  //       children: <Image src={img.src} style={{ maxWidth: 500 }} />,
  //     });
  //   };

  const openCropModal = (file) => {
    const imageSrc = URL.createObjectURL(file);

    modals.open({
      title: "Crop image",
      children: (
        <CropImage
          imageSrc={imageSrc}
          imageName={file.name}
          setNewHeaderImage={setNewImage}
          aspectRatio={9 / 16}
        />
      ),
    });

    // Clean up the URL object after usage
    return () => URL.revokeObjectURL(imageSrc);
  };
  const { mutate, isSuccess, isPending } = useCreateSpecialInvitation();

  const onSubmit = (data) => {
    if (data.isVisible) {
      data.isVisible = data.isVisible === "true";
    }

    // Remove targetedTagId if it is an empty string
    // This is done because the API will not accept an empty string
    // it will translate to 0 and that is not what we want
    // I've tried to use null but it didn't work
    // And refactoring the API repo to accept null as value
    // could break other things, so this is a temporary solution
    if (data.targetedTagId === "") {
      delete data.targetedTagId;
    }

    mutate(data);
  };

  // Set eventId value to currentEventId If user changes current event, we need
  // to update eventId value in the form.
  useEffect(() => {
    setValue("eventId", eventId);
  }, [eventId, setValue]);

  // If announcement is created successfully, reset form
  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <Container size={500}>
      <Title order={1}>Create Special Invitation</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <TextInput
            label="Title"
            mt="sm"
            name="title"
            control={control}
            placeholder="Your title"
          />

          <FileInput
            label="Header image"
            name="image"
            description="Image shown at the top of the mobile home page."
            placeholder="Upload file"
            clearable
            accept="image/png,image/jpeg"
            leftSection={<IconFileUpload />}
            style={{ width: "50%" }}
            onChange={(value) => {
              setNewImage(null);
              if (value) openCropModal(value);
            }}
          />

          <Select
            label="Targeted announcement"
            description="Select tag to target users"
            placeholder="Select targeted tag"
            mt="sm"
            name="targetedTagId"
            control={control}
            data={tagsOptions}
            allowDeselect={false}
          />
          <Select
            label="Visibility"
            placeholder="Select visibility"
            mt="sm"
            name="isVisible"
            control={control}
            data={[
              { value: "true", label: "Visible" },
              { value: "false", label: "Hidden" },
            ]}
          />
          <Button type="submit" mt="sm" loading={isPending}>
            Create invitation
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateSpecialInvite;
