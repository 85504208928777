import { Link } from "react-router-dom";
import { Group, ActionIcon, Badge, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash, IconSend2 } from "@tabler/icons-react";
import { DataTable } from "../DataTable";
import { useDeleteAnnouncement } from "../../api/announcements";
import deleteModal from "../deleteModal";
import openSendAnnouncementNotificationsModal from "./SendAnnouncementNotificationsModal";

const AnnouncementsTable = ({
  data,
  pagination,
  tagColors,
  hideTextField = false,
}) => {
  const { mutate, isSuccess, isError, error } = useDeleteAnnouncement();

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "title",
          render: (row) => (
            <Group>
              <Text size="sm">{row.title}</Text>
              {row.isVisible && (
                <Badge variant="dot" color="green">
                  Visible
                </Badge>
              )}
            </Group>
          ),
        },
        { accessor: "text", hidden: hideTextField },
        {
          accessor: "targetedTag",
          render: (row) => {
            if (!row.targetedTag?.title) return "All attendees";

            const tagColor = tagColors?.find(
              (tagColor) => tagColor.subtype === row.targetedTag.subtype
            );

            return (
              <Badge color={tagColor?.color || "blue"} variant="light">
                {row?.targetedTag?.title}
              </Badge>
            );
          },
          width: 150,
        },
        {
          accessor: "type",
          render: (row) => (
            <>
              {row.type === "info" && (
                <Badge variant="light" color="blue">
                  Info
                </Badge>
              )}
              {row.type === "warning" && (
                <Badge variant="light" color="orange">
                  Warning
                </Badge>
              )}
              {row.type === "special" && (
                <Badge variant="light" color="purple">
                  Special
                </Badge>
              )}
            </>
          ),
          width: 150,
        },
        {
          accessor: "actions",
          width: 150,
          render: (row) => (
            <Group justify="right">
              <Tooltip
                label={`${row.notifiedAt ? "Notification has been already sent" : "Send notification"}`}
              >
                <ActionIcon
                  size="md"
                  variant="light"
                  color="green"
                  onClick={() => openSendAnnouncementNotificationsModal(row.id)}
                  disabled={row.notifiedAt}
                >
                  <IconSend2 />
                </ActionIcon>
              </Tooltip>
              <ActionIcon
                size="md"
                variant="light"
                component={Link}
                to={`/app/announcements/${row.id}`}
              >
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                size="md"
                variant="light"
                color="red"
                onClick={() =>
                  deleteModal(
                    row,
                    `delete '${row.title}'`,
                    mutate,
                    isError,
                    error,
                    isSuccess
                  )
                }
              >
                <IconTrash />
              </ActionIcon>
            </Group>
          ),
        },
      ]}
      page={pagination.data.page}
      onPageChange={(page) =>
        pagination.setPagination({ ...pagination.data, page })
      }
      recordsPerPage={data?.pagination?.perPage}
      totalRecords={data?.pagination?.total}
    />
  );
};

export default AnnouncementsTable;
