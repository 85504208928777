import { Box, Text } from "@mantine/core";

function ExpandedRow({ description }) {
  return (
    <Box
      p="md"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Text size="sm" mb="xs" style={{ fontWeight: "bold" }}>
        Description:
      </Text>
      <Text
        size="sm"
        c={!description ? "red" : "black"}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {description || "No description provided for this session."}
      </Text>
    </Box>
  );
}

export default ExpandedRow;
