import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthContext } from "../services/context";
import Login from "./Auth/Login";

export default function AnonRoute() {
  const location = useLocation();
  const token = localStorage.getItem("token");

  const { user } = useContext(AuthContext);

  if (user && token) {
    return <Navigate to={location.state?.returnTo ?? "/app"} />;
  }

  if (location.pathname === "/login" || location.pathname === "/") {
    return <Login />;
  }

  return <Outlet />;
}
