import { Loader, Text } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetEvent } from "../../../api/event";
import { AuthContext } from "../../../services/context";
import EditForm from "./Form";

export default function Edit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const isSysAdmin = user?.role === "sysAdmin";

  const {
    data: responseData,
    isPending: isLoading,
    isError,
    error,
  } = useGetEvent(id);

  let content;
  if (isLoading) {
    content = <Loader mt={10} />;
  } else if (isError) {
    content = <Text c="red">{error.message}</Text>;
  } else if (responseData) {
    content = <EditForm data={responseData} isSysAdmin={isSysAdmin} />;
  }

  return content;
}
