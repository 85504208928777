import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Text } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";

import { usePatchEvent } from "../../../api/event";
import { reduceWhiteSpaces } from "../../../services/helper";
import { patterns } from "../../../services/patterns";

export default function EditForm({ data, isSysAdmin }) {
  const { id, name } = data;
  const { mutate, isPending: isLoading } = usePatchEvent(id);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: data.name,
      status: data.status,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 1, pattern: patterns.trimmedString },
        status: { type: "string", enum: ["draft", "active", "closed"] },
      },
      required: ["name"],
    }),
  });

  // If user is not a system admin,
  // disable previous status, because they can
  // update status in order 'draft' -> 'active' -> 'closed'
  const statusOptions = [
    {
      label: "Draft",
      value: "draft",
      disabled: !isSysAdmin && data.status !== "draft",
    },
    {
      label: "Active",
      value: "active",
      disabled:
        !isSysAdmin && (data.status !== "active" || data.status === "closed"),
    },
    { label: "Closed", value: "closed" },
  ];

  const onSubmit = (data) => {
    data.name = reduceWhiteSpaces(data.name);
    mutate(data);
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            type="text"
            name="name"
            defaultValue={name}
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />

          <Select
            label="Status"
            placeholder="Select status"
            name="status"
            data={statusOptions}
            control={control}
            mt="md"
            allowDeselect={false}
          />
          {!isSysAdmin && (
            <Text size="md" c="red" mt="md">
              Only system admins can edit events!
            </Text>
          )}
          <Button
            type="submit"
            mt="md"
            loading={isLoading}
            disabled={!isSysAdmin || !isDirty}
          >
            Edit event
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
