import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { IconClipboardPlus, IconEdit } from "@tabler/icons-react";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { DataTable } from "../DataTable";
import { useUpdateUser } from "../../api/user";
import { AuthContext } from "../../services/context";
import updateConfig from "../../services/userConfig";
import DeleteWorkspaceModal from "./DeleteWorkspaceModal";

function WorkspacesTable({
  data,
  isLoading,
  isSysAdmin,
  page,
  setPage,
  sortStatus,
  setSort,
}) {
  const { user, setUser } = useContext(AuthContext);
  const { mutate } = useUpdateUser({ id: user.id });

  return (
    <DataTable
      records={data?.data}
      columns={[
        { accessor: "name", sortable: true },
        {
          accessor: "actions",
          width: 150,
          render: (ws) => (
            <Group justify="center">
              <Tooltip label="Edit">
                <ActionIcon
                  size="md"
                  component={Link}
                  to={`${ws.id}`}
                  variant="light"
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <DeleteWorkspaceModal
                wsId={ws.id}
                wsName={ws.name}
                isSysAdmin={isSysAdmin}
              />
              <Tooltip label="Create new event">
                <ActionIcon
                  size="md"
                  disabled={!isSysAdmin}
                  component={Link}
                  to={"/app/events/create"}
                  color="darkBlue"
                  variant="light"
                  onClick={() =>
                    updateConfig({ workspace: ws, user, setUser, mutate })
                  }
                >
                  <IconClipboardPlus />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
      sortStatus={sortStatus}
      onSortStatusChange={setSort}
    />
  );
}

export default WorkspacesTable;
