import { useContext, useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Container,
  Flex,
  List,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconX,
  IconChevronDown,
  IconChevronUp,
  IconPlus,
  IconInfoCircle,
} from "@tabler/icons-react";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useGetSponsorLevels, usePatchSponsorLevels } from "../../api/sponsors";
import deleteModal from "../deleteModal";

function SponsorLevels() {
  const { user } = useContext(AuthContext);
  const workspaceId = user?.config.adminUI?.activeWorkspaceId;
  const { eventId } = getCurrentWorkspace(user);

  const { data: sponsorLevels } = useGetSponsorLevels({ eventId });
  const sortedLevels = sponsorLevels?.sort((a, b) => a.order - b.order);

  const {
    mutate,
    isSuccess,
    isError,
    error: patchError,
  } = usePatchSponsorLevels();

  useEffect(() => {
    if (sponsorLevels) setLevels(sortedLevels);
  }, [sortedLevels, sponsorLevels]);

  useEffect(() => {
    if (isError) {
      setError(patchError);
    }
  }, [isError, patchError]);

  const [levels, setLevels] = useState([]);
  const [editingLevelId, setEditingLevelId] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [newLevelName, setNewLevelName] = useState("");
  const [showAddInput, setShowAddInput] = useState(false);
  const [error, setError] = useState("");

  const handleEditClick = (level) => {
    setEditingLevelId(level.id);
    setEditedName(level.name);
  };

  const handleSave = () => {
    // Prevent saving if the edited name already exists
    const levelAlreadyExists = levels.find(
      (level) =>
        level.name.toLowerCase() === editedName.toLowerCase() &&
        level.id !== editingLevelId
    );
    if (levelAlreadyExists) {
      setError(
        <>
          Level name <strong>{levelAlreadyExists.name}</strong> already exists.
          <br />
          Please choose a different name.
        </>
      );
      return;
    }
    setLevels((prevLevels) =>
      prevLevels.map((level) =>
        level.id === editingLevelId ? { ...level, name: editedName } : level
      )
    );
    setEditingLevelId(null);
    setEditedName("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const updateOrder = (updatedLevels) => {
    return updatedLevels.map((level, idx) => ({ ...level, order: idx + 1 }));
  };

  const moveLevel = (order, direction) => {
    setLevels((prevLevels) => {
      const index = prevLevels.findIndex((level) => level.order === order);
      if (
        (direction === "up" && index > 0) ||
        (direction === "down" && index < prevLevels.length - 1)
      ) {
        const newLevels = [...prevLevels];
        const swapIndex = direction === "up" ? index - 1 : index + 1;
        [newLevels[index], newLevels[swapIndex]] = [
          newLevels[swapIndex],
          newLevels[index],
        ];

        return updateOrder(newLevels);
      }
      return prevLevels;
    });
  };

  const handleAddLevel = () => {
    // Prevent adding empty names
    if (newLevelName.trim() === "") {
      setError("Level name cannot be empty");
      return;
    }

    // Prevent adding duplicate names
    const levelAlreadyExists = levels.find(
      (level) => level.name.toLowerCase() === newLevelName.toLowerCase()
    );
    if (levelAlreadyExists) {
      setError(
        <>
          Level name <strong>{levelAlreadyExists.name}</strong> already exists.
          <br />
          Please choose a different name.
        </>
      );
      return;
    }

    const newLevel = {
      name: newLevelName,
      order: levels.length + 1, // Set order based on the current levels
    };

    // Add the new level and update state
    setLevels((prevLevels) => {
      const updatedLevels = [...prevLevels, newLevel];
      return updateOrder(updatedLevels);
    });
    setNewLevelName(""); // Clear input field
    setShowAddInput(false); // Hide input after adding
    setError(""); // Clear error message
  };

  const handleCancelAdd = () => {
    setNewLevelName("");
    setShowAddInput(false);
  };

  const handleDeleteLevel = (level) => {
    deleteModal(
      level,
      `delete ${level.name} sponsor level`,
      (id) => {
        // Remove the level
        setLevels((prevLevels) => {
          const updatedLevels = prevLevels.filter((l) => l.id !== id);
          // Update the order of the remaining levels
          return updatedLevels.map((l, index) => ({ ...l, order: index + 1 }));
        });
      },
      false,
      null,
      true
    );
  };

  const handleSubmit = () => {
    mutate({ levels, eventId, workspaceId });
  };

  const hasChanged = JSON.stringify(levels) !== JSON.stringify(sortedLevels);

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <Text style={{ textAlign: "center", fontWeight: 700 }}>
          Sponsor Levels
        </Text>
        {levels.length > 0 && (
          <Paper shadow="md" p="sm" withBorder mt="xs" mb="md">
            <Flex style={{ alignItems: "center", gap: 5 }}>
              <IconInfoCircle />
              <Container style={{ flexDirection: "column" }}>
                <Text style={{ fontSize: 13 }}>
                  Click on a level to edit the name and press enter.
                </Text>
                <Text style={{ fontSize: 13 }}>Use the arrows to reorder.</Text>
              </Container>
            </Flex>
          </Paper>
        )}

        <List type="ordered" withPadding>
          {levels?.map((level, index) => (
            <Flex key={level.order} justify="space-between" align="center">
              <Text>{level.order}.</Text>
              {editingLevelId === level.id ? (
                <TextInput
                  value={editedName}
                  onChange={(e) => {
                    setEditedName(e.currentTarget.value);
                    setError("");
                  }}
                  onKeyDown={handleKeyDown} // Handle Enter key
                  style={{ marginInline: 15, margin: 5, width: "70%" }}
                  autoFocus
                />
              ) : (
                <Button
                  variant="outline"
                  style={{
                    marginInline: 15,
                    margin: 5,
                    width: "70%",
                  }}
                  justify="space-between"
                  onClick={() => handleEditClick(level)}
                  disabled={isSuccess}
                  rightSection={
                    <>
                      {index > 0 && (
                        <IconChevronUp
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent edit mode
                            moveLevel(level.order, "up");
                          }}
                          disabled={isSuccess}
                        />
                      )}
                      {index < levels.length - 1 && (
                        <IconChevronDown
                          disabled={isSuccess}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent edit mode
                            moveLevel(level.order, "down");
                          }}
                        />
                      )}
                    </>
                  }
                >
                  {level.name}
                </Button>
              )}
              <ActionIcon
                color="red"
                title="Delete"
                onClick={() => handleDeleteLevel(level)}
                disabled={isSuccess}
              >
                <IconX />
              </ActionIcon>
            </Flex>
          ))}
        </List>

        {/* Show/hide input for adding new level */}
        {showAddInput && (
          <Flex
            align="center"
            style={{ marginTop: 16, marginInline: 25, marginBottom: 16 }}
          >
            <TextInput
              autoFocus
              value={newLevelName}
              onChange={(e) => setNewLevelName(e.currentTarget.value)}
              placeholder="New level name"
              style={{ width: "70%", marginInline: 25 }}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleAddLevel();
              }}
            />
            <div style={{ display: "flex", gap: "8px" }}>
              <Button onClick={handleAddLevel} color="green">
                Add Level
              </Button>
              <Button onClick={handleCancelAdd} color="red">
                Cancel
              </Button>
            </div>
          </Flex>
        )}

        {/* Show error message if any */}
        {error && (
          <Text c="red" align="center" mt="md">
            {error.message || error}
          </Text>
        )}

        <Flex
          justify={"center"}
          align={"center"}
          style={{ flexDirection: "column" }}
        >
          <Button
            mt="md"
            justify="space-between"
            rightSection={<IconPlus />}
            onClick={() => setShowAddInput(true)}
            disabled={isSuccess}
          >
            Add Sponsor Level
          </Button>

          {hasChanged && (
            <Container
              mt={"xs"}
              style={{
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Text c="dimmed" style={{ fontSize: 13 }}>
                You have unsaved changes.
              </Text>
              <Text c="dimmed" style={{ fontSize: 13 }}>
                Please make sure to save before leaving this page.
              </Text>
            </Container>
          )}

          <Button
            type="submit"
            mt="md"
            style={{ alignSelf: "flex-end" }}
            onClick={handleSubmit}
            disabled={isSuccess || !hasChanged}
          >
            Save
          </Button>
        </Flex>
      </Paper>
    </Container>
  );
}

export default SponsorLevels;
