import { DataTable } from "mantine-datatable";
import { useParams } from "react-router-dom";

import { useGetWorkspaceUsers } from "../../../api/workspace";
import RemoveUserModalButton from "./RemoveUserModal";
import RoleBadge from "./../../RoleBadges";
import { useContext } from "react";
import { AuthContext } from "../../../services/context";

export const WorkspaceMembersTable = () => {
  const { id } = useParams();
  const { data: workspaceUsers, isPending } = useGetWorkspaceUsers(id);
  const { user } = useContext(AuthContext);

  const columns = [
    {
      accessor: "name",
      render: (row) => `${row.firstName} ${row.lastName}`,
    },
    { accessor: "email" },
    {
      accessor: "role",
      render: (row) => <RoleBadge roleName={row.role} />,
    },
  ];

  if (user.role === "sysAdmin") {
    columns.push({
      accessor: "actions",
      title: "Actions",
      textAlign: "right",
      render: (row) => <RemoveUserModalButton userId={row.id} />,
    });
  }

  return (
    <DataTable
      records={workspaceUsers}
      columns={columns}
      fetching={isPending}
      mt={16}
    />
  );
};
