import React, { useContext } from "react";
import NotFound from "../pages/NotFound";
import { AuthContext } from "../services/context";
import { Outlet } from "react-router-dom";

// This component is used to protect routes that are only accessible to
// sysAdmins. It checks the user's role and if it's not sysAdmin, it renders
// a NotFound component.
function SysAdminRoute() {
  const { user } = useContext(AuthContext);

  if (user?.role !== "sysAdmin") {
    return <NotFound />;
  }

  return <Outlet />;
}

export default SysAdminRoute;
