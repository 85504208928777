import { useContext, useState } from "react";

import { useGetSessions } from "../api/session";

import SessionsTable from "../components/Polls/SessionsTable";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";

function Sessions() {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState({
    columnAccessor: "starting",
    direction: "asc",
  });

  const currentWorkspace = getCurrentWorkspace(user);
  const eventId = currentWorkspace.eventId;
  const workspaceId = currentWorkspace.id;

  const sessionParams = {
    page,
    eventId,
    workspaceId,
    showPollsCount: true,
    showOnlySessionsWithPolls: true,
    sort,
  };

  const { data, isPending: isLoading } = useGetSessions(sessionParams);

  return (
    <>
      <SessionsTable
        data={data}
        isLoading={isLoading}
        role={user.role}
        page={page}
        setPage={setPage}
        sortStatus={sort}
        setSort={setSort}
      />
    </>
  );
}

export default Sessions;
