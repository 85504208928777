import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";

export default function deleteModal(
  data,
  title,
  mutate,
  isError,
  error,
  isSuccess
) {
  modals.openConfirmModal({
    title: `You are about to ${title}. Are you sure?`,
    centered: true,
    children: <Text size="sm">Please confirm to proceed.</Text>,
    labels: { confirm: "Confirm", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onCancel: () => {
      modals.close();
    },
    onConfirm: async () => {
      mutate(data.id);

      if (isSuccess) {
        modals.close();
      }
      if (isError) {
        console.log(error);
      }
    },
  });
}
