import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Container, Paper, Text, Stack } from "@mantine/core";
import { useContext, useEffect } from "react";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import {
  Checkbox,
  Select,
  TextInput,
  Textarea,
  Radio,
} from "react-hook-form-mantine";
import { v4 as uuidv4 } from "uuid";
import { useGetSessions } from "../../api/session";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreatePoll } from "../../api/polls";
import { createPollsSchema } from "./schema";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function PollForm() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);

  const { data: sessionsData } = useGetSessions({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });

  const initialValues = {
    polls: [
      {
        id: uuidv4(),
        sessionId: "",
        title: "",
        description: "",
        options: [
          { id: uuidv4(), title: "" },
          { id: uuidv4(), title: "" },
        ],
        workspaceId: currentWorkspace.id,
        eventId: currentWorkspace.eventId,
        type: "singleChoice",
        followUpConditionType: "isEqual",
        followUpConditionValue: null,
        followUpPollId: null,
      },
    ],
    common: {
      visibility: "visible",
      status: "open",
      results: "shared",
    },
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(createPollsSchema),
  });

  const { control, handleSubmit, watch, setValue, getValues } = methods;
  const {
    fields: pollFields,
    append: appendPoll,
    remove: removePoll,
  } = useFieldArray({
    control,
    name: "polls",
  });

  const { mutate, isPending } = useCreatePoll();
  const [prevTypes, setPrevTypes] = useState([]);
  const navigate = useNavigate();
  const sessionId = watch("polls.0.sessionId");

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.includes("type")) {
        const pollIndex = parseInt(name.split(".")[1], 10);
        const currentType = value.polls[pollIndex]?.type;

        // Initialize the previous types array if not already done
        setPrevTypes((prevTypes) => {
          const updatedPrevTypes = [...prevTypes];
          if (!updatedPrevTypes[pollIndex]) {
            updatedPrevTypes[pollIndex] = currentType;
          }
          return updatedPrevTypes;
        });

        const prevType = prevTypes[pollIndex];

        if (currentType !== prevType) {
          // Update the type in the prevTypes array
          setPrevTypes((prevTypes) => {
            const updatedPrevTypes = [...prevTypes];
            updatedPrevTypes[pollIndex] = currentType;
            return updatedPrevTypes;
          });

          if (currentType === "text") {
            // Remove all options if type is 'text'
            setValue(`polls.${pollIndex}.options`, []);
            setValue(`polls.${pollIndex}.followUpConditionValue`, "");
          } else if (
            currentType !== "text" &&
            (!value.polls[pollIndex].options ||
              value.polls[pollIndex].options.length === 0)
          ) {
            // Add new options if options are empty
            setValue(`polls.${pollIndex}.options`, [
              { id: uuidv4(), title: "" },
              { id: uuidv4(), title: "" },
            ]);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, prevTypes, setValue]);

  const onSubmit = (data) => {
    const submissionData = data.polls.map((poll, index) => {
      const isLastPoll = index === data.polls.length - 1;
      return {
        ...poll,
        options: poll.type === "text" ? null : poll.options,
        followUpConditionType: isLastPoll ? null : poll.followUpConditionType,
        followUpConditionValue: isLastPoll
          ? null
          : poll.type === "text"
            ? [{ text: poll.followUpConditionValue }]
            : poll.followUpConditionValue,
        isVisible: data.common.visibility === "visible",
        areResultsVisible: data.common.results === "shared",
        status: data.common.status,
      };
    });

    mutate(submissionData, {
      onSuccess: () => {
        navigate(`/app/polls/${sessionId}`);
      },
    });
  };

  const handleAddPoll = () => {
    appendPoll({
      id: uuidv4(),
      sessionId: watch("polls.0.sessionId"),
      title: "",
      description: "",
      options: [
        { id: uuidv4(), title: "" },
        { id: uuidv4(), title: "" },
      ],
      workspaceId: currentWorkspace.id,
      eventId: currentWorkspace.eventId,
      type: "singleChoice",
      followUpConditionType: "isEqual",
      followUpConditionValue: [],
      followUpPollId: null,
    });
  };

  useEffect(() => {
    if (sessionId) {
      pollFields.forEach((_, index) => {
        setValue(`polls.${index}.sessionId`, sessionId);
      });
    }
  }, [watch, pollFields, setValue]);

  const handleCheckboxChange = ({ isChecked, value, pollIndex }) => {
    const prevValues = getValues(`polls.${pollIndex}.followUpConditionValue`);

    const followUpConditionValue = Array.isArray(prevValues) ? prevValues : [];

    const updatedFollowUpConditionValue = isChecked
      ? [...followUpConditionValue, { id: value }]
      : followUpConditionValue.filter((item) => item.id !== value);

    setValue(
      `polls.${pollIndex}.followUpConditionValue`,
      updatedFollowUpConditionValue
    );
  };

  return (
    <FormProvider {...methods}>
      <Container size={500}>
        <Paper withBorder shadow="md" p={16} mt={16} radius="md">
          <form onSubmit={handleSubmit(onSubmit)}>
            {pollFields.map((poll, pollIndex) => (
              <>
                <div
                  key={poll.id}
                  style={{
                    marginLeft: pollIndex > 0 ? 20 : 0,
                    paddingLeft: pollIndex > 0 ? 16 : 0,
                    borderLeft: pollIndex > 0 ? "1px solid #dee2e6 " : "none",
                  }}
                >
                  {pollIndex === 0 && (
                    <>
                      <Select
                        control={control}
                        label="Session"
                        placeholder="Select session"
                        name={`polls.${pollIndex}.sessionId`}
                        data={sessionsData?.map((s) => ({
                          value: `${s.id}`,
                          label: s.title,
                        }))}
                        allowDeselect={false}
                      />
                    </>
                  )}
                  {pollIndex > 0 && (
                    <div style={{ marginTop: 20 }}>
                      <label style={styles.label}>Question Condition</label>
                      <Radio.Group
                        control={control}
                        name={`polls.${pollIndex - 1}.followUpConditionType`}
                      >
                        <Stack>
                          <Radio
                            control={control}
                            value="isEqual"
                            name={`polls.${pollIndex - 1}.followUpConditionType`}
                            label="is Equal"
                          />
                          <Radio
                            control={control}
                            value="isNotEqual"
                            name={`polls.${pollIndex - 1}.followUpConditionType`}
                            label="is Not Equal"
                          />
                        </Stack>
                      </Radio.Group>
                      {watch(`polls.${pollIndex - 1}.type`) === "text" ? (
                        <TextInput
                          label="Condition Value"
                          placeholder="Enter text value"
                          type="text"
                          name={`polls.${pollIndex - 1}.followUpConditionValue`}
                          control={control}
                          mt="md"
                        />
                      ) : (
                        <Stack>
                          <div style={{ marginTop: 10, marginBottom: -10 }}>
                            <label style={styles.label}>
                              Conditions Value(s)
                            </label>
                          </div>
                          {watch(`polls.${pollIndex - 1}.options`).map(
                            (option) => (
                              <Checkbox
                                key={option.id}
                                label={option.title}
                                control={control}
                                name={`polls.${pollIndex - 1}.followUpConditionValue.${option.id}`}
                                checked={
                                  Array.isArray(
                                    watch(
                                      `polls.${pollIndex - 1}.followUpConditionValue`
                                    )
                                  )
                                    ? watch(
                                        `polls.${pollIndex - 1}.followUpConditionValue`
                                      )
                                        ?.map((i) => i.id)
                                        ?.includes(option.id)
                                    : false
                                }
                                onChange={(e) => {
                                  handleCheckboxChange({
                                    isChecked: e.target.checked,
                                    value: option.id,
                                    pollIndex: pollIndex - 1,
                                  });
                                }}
                                value={option.id}
                              />
                            )
                          )}
                        </Stack>
                      )}
                    </div>
                  )}
                  <TextInput
                    label="Question"
                    placeholder="Type your question here"
                    type="text"
                    name={`polls.${pollIndex}.title`}
                    control={control}
                    withAsterisk
                    mt="md"
                  />
                  <Textarea
                    label="Description"
                    placeholder="Type your description here"
                    type="text"
                    name={`polls.${pollIndex}.description`}
                    control={control}
                    mt="md"
                  />
                  <div style={{ marginTop: 20 }}>
                    <label style={styles.label}>Question Type</label>
                    <Radio.Group
                      control={control}
                      name={`polls.${pollIndex}.type`}
                    >
                      <Stack>
                        <Radio
                          control={control}
                          value="singleChoice"
                          name={`polls.${pollIndex}.type`}
                          label="Single choice question"
                        />
                        <Radio
                          control={control}
                          value="multipleChoice"
                          name={`polls.${pollIndex}.type`}
                          label="Multiple choice question"
                        />
                        <Radio
                          control={control}
                          value="text"
                          name={`polls.${pollIndex}.type`}
                          label="Text question"
                        />
                      </Stack>
                    </Radio.Group>
                    {watch(`polls.${pollIndex}.type`) !== "text" && (
                      <div style={{ marginTop: 20 }}>
                        <label style={styles.label}>Options</label>
                        <OptionsFieldArray
                          control={control}
                          pollIndex={pollIndex}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <Text style={{ marginBottom: -10 }}>
                      Follow Up Question
                    </Text>
                    {pollFields.length <= pollIndex + 1 && (
                      <Button
                        type="button"
                        mt="md"
                        onClick={() => handleAddPoll(pollIndex)}
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  {pollFields.length > pollIndex + 1 && (
                    <Button
                      color="red"
                      type="button"
                      mt="md"
                      onClick={() => removePoll(pollIndex + 1)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </>
            ))}

            <div style={styles.wrapper}>
              <Select
                control={control}
                label="Visibility"
                name={"common.visibility"}
                data={[
                  { value: "visible", label: "Visible" },
                  { value: "hidden", label: "Hidden" },
                ]}
                allowDeselect={false}
              />
              <Select
                control={control}
                label="Status"
                name={"common.status"}
                data={[
                  {
                    value: "open",
                    label: "Open",
                  },
                  {
                    value: "closed",
                    label: "Closed",
                  },
                ]}
                allowDeselect={false}
              />
              <Select
                control={control}
                label="Results"
                name={"common.results"}
                data={[
                  { value: "shared", label: "Shared" },
                  { value: "hidden", label: "Hidden" },
                ]}
                description="Are the results of this poll shared with the users after they submit their vote?"
                allowDeselect={false}
              />
            </div>

            <Button type="submit" mt="md" loading={isPending}>
              Create Polls
            </Button>
          </form>
        </Paper>
      </Container>
    </FormProvider>
  );
}

function OptionsFieldArray({ control, pollIndex }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `polls.${pollIndex}.options`,
  });

  return (
    <>
      {fields.map((option, optionIndex) => (
        <div key={option.id} style={{ display: "flex", marginBottom: 20 }}>
          <TextInput
            placeholder="Option"
            type="text"
            name={`polls.${pollIndex}.options.${optionIndex}.title`}
            control={control}
          />
          <Button
            color="gray"
            type="button"
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() =>
              append({
                id: uuidv4(),
                title: "",
              })
            }
          >
            +
          </Button>
          <Button
            color="gray"
            type="button"
            onClick={() => remove(optionIndex)}
            disabled={fields.length === 2}
          >
            -
          </Button>
        </div>
      ))}
    </>
  );
}

export default function CreatePoll() {
  return <PollForm />;
}

const styles = {
  label: {
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  },
  wrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
};
