import {
  ActionIcon,
  Card,
  Container,
  Flex,
  Group,
  Loader,
  Title,
} from "@mantine/core";
import { IconClipboardPlus, IconEdit } from "@tabler/icons-react";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { useUpdateUser } from "../../api/user";
import { AuthContext } from "../../services/context";
import updateConfig from "../../services/userConfig";
import DeleteWorkspaceModal from "./DeleteWorkspaceModal";

export default function WorkspacesCards({ data, isLoading, isSysAdmin }) {
  const { user, setUser } = useContext(AuthContext);
  const { mutate } = useUpdateUser({ id: user.id });

  return isLoading ? (
    <Flex justify="center">
      <Loader m={10} />
    </Flex>
  ) : (
    <Container mt="md">
      {data?.data.map((ws) => (
        <Card shadow="sm" p="lg" radius="md" withBorder mb={5} key={ws.id}>
          <Flex direction="row" justify="space-between">
            <Title order={5}>{ws.name}</Title>
            <Group>
              <ActionIcon
                size="md"
                component={Link}
                to={`${ws.id}`}
                variant="light"
              >
                <IconEdit />
              </ActionIcon>
              <DeleteWorkspaceModal wsId={ws.id} wsName={ws.name} />
              <ActionIcon
                size="md"
                disabled={!isSysAdmin}
                component={Link}
                to={"/app/events/create"}
                color="darkBlue"
                variant="light"
                onClick={() =>
                  updateConfig({ workspace: ws, user, setUser, mutate })
                }
              >
                <IconClipboardPlus />
              </ActionIcon>
            </Group>
          </Flex>
        </Card>
      ))}
    </Container>
  );
}
