import { Avatar, Group, Text, UnstyledButton } from "@mantine/core";
import { Link } from "react-router-dom";

import RoleBadges from "../RoleBadges";
import classes from "./UserButton.module.css";

export function UserButton({ user }) {
  const renderAvatar = () => {
    return user?.picture ? (
      <Avatar src={user.picture} radius="xl" />
    ) : (
      <Avatar radius="xl" color="blue">
        {user?.firstName?.[0]}
        {user?.lastName?.[0]}
      </Avatar>
    );
  };

  return (
    <UnstyledButton
      className={classes.user}
      component={Link}
      to={"/app/profile"}
    >
      <Group>
        {renderAvatar()}
        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {user.firstName} {user.lastName}
          </Text>
          <RoleBadges roleName={user?.role} variant="light" size="xs" />
        </div>
      </Group>
    </UnstyledButton>
  );
}
