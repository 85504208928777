import { Flex, Loader, Text } from "@mantine/core";

import { useContext } from "react";
import { useGetEvent } from "../../../api/event";
import { getCurrentWorkspace } from "../../../services/userConfig";
import { AuthContext } from "../../../services/context";
import CustomizeEvent from "./CustomizeEvent";
import Locations from "./Locations";
import EventDates from "./EventDates";

export default function Customize() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);

  const isSysAdmin = user?.role === "sysAdmin";

  const { data, isPending, isError, error } = useGetEvent(
    currentWorkspace?.eventId
  );

  if (isPending) {
    return <Loader mt={10} />;
  }
  if (isError) {
    return <Text c="red">{error.message}</Text>;
  }
  return (
    <Flex gap="md" wrap="wrap">
      <CustomizeEvent data={data} isSysAdmin={isSysAdmin} />
      <Flex direction="column" flex="1">
        <EventDates event={data} />
        <Locations />
      </Flex>
    </Flex>
  );
}
