import { deepSanitize } from "@congrs/utils";
import { ajvResolver } from "@hookform/resolvers/ajv";

const resolver =
  (schema, ...options) =>
  (data, ...rest) => {
    const clone = deepSanitize(data);
    return ajvResolver(schema, ...options)(clone, ...rest);
  };

export default resolver;
