import { ActionIcon } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";

import { useDeleteWorkspace } from "../../api/workspace";

export default function DeleteWorkspaceModal({ wsId, wsName, isSysAdmin }) {
  const { mutate } = useDeleteWorkspace(wsId);

  const openModal = () =>
    modals.openConfirmModal({
      title: "Delete workspace",
      children: `Are you sure you want to delete ${wsName} workspace? This action cannot be undone.`,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        mutate();
      },
    });

  return (
    <ActionIcon
      onClick={openModal}
      aria-label="Delete workspace"
      color="red"
      variant="light"
      disabled={!isSysAdmin}
    >
      <IconTrash />
    </ActionIcon>
  );
}
