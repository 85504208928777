import { Image } from "@mantine/core";
import { Link } from "react-router-dom";

import logo from "../assets/logo-02.svg";

function Logo({ ...props }) {
  return (
    <Link to="/app">
      <Image
        src={logo}
        alt="Logo"
        style={{ objectFit: "contain" }}
        {...props}
      />
    </Link>
  );
}

export default Logo;
