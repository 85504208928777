import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Text } from "@mantine/core";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";
import { AuthContext } from "../../services/context";

import { useCreateWorkspace } from "../../api/workspace";

export default function CreateWorkSpace() {
  const { user } = useContext(AuthContext);
  const isSysAdmin = user?.role === "sysAdmin";

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 1 },
      },
      required: ["name"],
    }),
  });

  const { mutate, isPending } = useCreateWorkspace();

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(mutate)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />
          {!isSysAdmin && (
            <Text size="md" c="red" mt="md">
              Only system admins can create new workspaces!
            </Text>
          )}
          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={!isSysAdmin}
          >
            Create workspace
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
