import { Image } from "@mantine/core";
import * as eva from "eva-icons";

const findIcon = (name) => {
  const iconPack = eva.icons;
  const icon = iconPack[name];
  return icon ? icon : null;
};

export const EvaIcon = ({ name, ...props }) => {
  eva.replace();
  const icon = findIcon(name);

  if (!icon) {
    return null;
  }

  return (
    <Image src={`data:image/svg+xml;base64,${btoa(icon.toSvg())}`} {...props} />
  );
};
