import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetSessions = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["sessions", params] },
    fetchOptions: { url: "/sessions", method: "GET", params },
  });

export const useGetSession = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["session", { id }], enabled: !!id },
    fetchOptions: { url: `/sessions/${id}`, method: "GET" },
  });

export const usePatchSession = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "usePatchSession" },
    fetchOptions: { url: `/sessions/${id}`, method: "PATCH" },
    invalidateQueryKey: "sessions",
    successMessage: { title: "Session has been updated", color: "green" },
  });

export const useCreateSession = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateSession" },
    fetchOptions: { url: "/sessions", method: "POST" },
    invalidateQueryKey: "sessions",
    successMessage: { title: "Session has been created", color: "green" },
  });

export const useDeleteSession = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteSession" },
    fetchOptions: { url: "/sessions", method: "DELETE" },
    invalidateQueryKey: "sessions",
    successMessage: { title: "Session has been deleted", color: "green" },
  });

export const usePollsToggle = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "sessionPolls" },
    fetchOptions: {
      url: "/polls/toggleVisibilityOnMobileHomepage",
      method: "PATCH",
    },
    invalidateQueryKey: "sessions",
    successMessage: { title: "Polls have been toggled", color: "green" },
  });

export const useCancelSession = (sessionId, isCanceled) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCancelSession" },
    fetchOptions: { url: `/sessions/${sessionId}/cancel`, method: "PATCH" },
    invalidateQueryKey: "sessions",
    successMessage: {
      title: `${isCanceled ? "Session has been restored successfully" : "Session has been canceled successfully"}`,
      color: "green",
    },
  });
