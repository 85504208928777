import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Text } from "@mantine/core";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";

import { useCreateEvent } from "../../api/event";
import { AuthContext } from "../../services/context";
import { reduceWhiteSpaces } from "../../services/helper";
import { patterns } from "../../services/patterns";
import { getCurrentWorkspace } from "../../services/userConfig";

function CreateEvent() {
  const { user } = useContext(AuthContext);

  const isSysAdmin = user?.role === "sysAdmin";
  const workspaceId = getCurrentWorkspace(user)?.id;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      wsId: "",
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        wsId: {
          type: "string",
        },
        name: {
          type: "string",
          minLength: 1,
          pattern: patterns.trimmedString,
        },
      },
    }),
  });

  const { mutate, isPending, isSuccess } = useCreateEvent();

  const onSubmit = (data) => {
    data.name = reduceWhiteSpaces(data.name);
    mutate({ ...data, wsId: workspaceId });
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />
          {!isSysAdmin && (
            <Text size="md" c="red" mt="md">
              Only system admins can create new events!
            </Text>
          )}

          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={!isSysAdmin || isSuccess}
          >
            Create event
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateEvent;
