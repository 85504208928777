import { Button, Flex, Group, Loader, Text } from "@mantine/core";
import { modals } from "@mantine/modals";

import LocationForm from "./LocationForm";
import {
  useCreateEventLocation,
  useDeleteEventLocation,
  useGetLocation,
  usePatchEventLocation,
} from "../../../api/event";

function CreateLocation({ eventId }) {
  const { mutate, isPending } = useCreateEventLocation(eventId);

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        modals.closeAll();
      },
    });
  };

  return (
    <Flex direction="column">
      <LocationForm
        onSubmit={onSubmit}
        eventId={eventId}
        formId="create-location"
      />
      <Button type="submit" form="create-location" loading={isPending} mt="sm">
        Create
      </Button>
    </Flex>
  );
}

export const openCreateLocationModal = (eventId) => {
  modals.open({
    title: "Create Location",
    children: <CreateLocation eventId={eventId} />,
  });
};

const EditLocation = ({ eventId, locationId }) => {
  const { data: location, isPending } = useGetLocation(eventId, locationId);

  const { mutate, isPending: isMutating } = usePatchEventLocation(
    eventId,
    locationId
  );

  if (isPending) {
    return <Loader mt={10} />;
  }

  const onSubmit = (data) => {
    console.log(data);
    mutate(data, { onSettled: () => modals.closeAll() });
  };

  return (
    <Flex direction="column">
      <LocationForm
        onSubmit={onSubmit}
        eventId={eventId}
        formId="edit-location"
        location={location}
      />
      <Button type="submit" form="edit-location" loading={isMutating} mt="sm">
        Save
      </Button>
    </Flex>
  );
};

export const openEditLocationModal = (eventId, locationId) => {
  modals.open({
    title: "Edit Location",
    children: <EditLocation eventId={eventId} locationId={locationId} />,
  });
};

const DeleteLocationModal = ({ eventId, locationId }) => {
  const { mutate } = useDeleteEventLocation(eventId, locationId);

  return (
    <Flex direction="column">
      <Text>Are you sure you want to delete this location?</Text>
      <Group justify="flex-end" gap="xs" mt="sm">
        <Button
          onClick={() => {
            modals.closeAll();
          }}
        >
          Cancel
        </Button>
        <Button
          color="red"
          onClick={() => {
            mutate(null, { onSettled: () => modals.closeAll() });
          }}
        >
          Delete
        </Button>
      </Group>
    </Flex>
  );
};

export const openDeleteLocationModal = (eventId, locationId) => {
  modals.open({
    title: "Delete Location",
    children: <DeleteLocationModal eventId={eventId} locationId={locationId} />,
  });
};
