import { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Badge,
  Group,
  ActionIcon,
  Avatar,
  TextInput,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconEdit,
  IconX,
  IconSearch,
  IconSend2,
  IconTrash,
} from "@tabler/icons-react";
import { InlineEditableTable } from "../InlineEditableTable";
import AttendeeSwitch from "./AttendeeSwitch";
import { CurrencyInput } from "./CurrencyInput";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreateAttendee } from "../../api/attendees";
import { useDeleteAttendee } from "../../api/attendees";
import deleteModal from "../deleteModal";

function AttendeesTable({
  data,
  pagination,
  sorting,
  isPending,
  actions,
  selectedRecords,
  onSelectedRecordsChange,
  filter,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { mutate } = useCreateAttendee();

  const { data: tagsData, isPending: tagsPending } = useGetPeopleTagsBySubtype({
    eventId: currentEventId,
    subtype: "categoryOfUser",
  });

  const getAttendeeTagId = (tagsData) => {
    if (tagsData) {
      return tagsData.find((tag) => tag.title.toLowerCase() === "attendee")?.id;
    }
  };

  const tags = tagsData?.map((tag) => ({
    value: String(tag.id),
    label: tag.title,
  }));
  const {
    mutate: deleteAttendee,
    isError,
    isSuccess,
    error,
  } = useDeleteAttendee();

  const updateUrlWithSelectedIds = (selectedIds) => {
    const searchParams = new URLSearchParams(location.search);

    if (selectedIds.length > 0) {
      searchParams.set("selectedIds", selectedIds.join(","));
    } else {
      searchParams.delete("selectedIds");
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const saveRowAdd = (row) => {
    const attendee = {
      eventId: currentEventId,
      userData: {
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        categoryOfUser: row.category,
      },
    };

    mutate(attendee);
  };

  const isValidEmail = (email) => {
    const emailRegex =
      /^(?!.*\.\.)[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <InlineEditableTable
        data={data?.data}
        createEmptyRow={() => ({
          id: "temp",
          firstName: "",
          lastName: "",
          email: "",
          category: getAttendeeTagId(tagsData),
        })}
        validateRow={(row) => {
          const errors = {};

          if (!row.firstName) {
            errors.firstName = "First name is required";
          }

          if (!row.lastName) {
            errors.lastName = "Last name is required";
          }

          if (!row.email) {
            errors.email = "Email is required";
          }

          if (!isValidEmail(row.email)) {
            errors.email = "Invalid email format";
          }

          return errors;
        }}
        onSave={(row) => {
          saveRowAdd(row);
        }}
        columns={[
          {
            accessor: "picture",
            width: "0%",
            editable: false,
            render: (row) => (
              <Group justify="center">
                <Avatar
                  src={row?.picture}
                  size="md"
                  radius="sm"
                  alt="avatar"
                  color="initials"
                  name={`${row?.firstName} ${row?.lastName}`}
                />
              </Group>
            ),
            align: "center",
          },
          {
            accessor: "firstName",
            editable: true,
            sortable: true,
            inputProps: {
              placeholder: "First name",
            },
            filter: (
              <FilterSearch
                query={filter.data}
                setQuery={filter.setFilter}
                columnName="firstName"
              />
            ),
            filtering: filter?.data?.firstName,
          },
          {
            accessor: "lastName",
            sortable: true,
            editable: true,
            inputProps: {
              placeholder: "Last name",
            },
            filter: (
              <FilterSearch
                query={filter.data}
                setQuery={filter.setFilter}
                columnName="lastName"
              />
            ),
            filtering: filter?.data?.lastName,
          },
          {
            accessor: "email",
            sortable: true,
            editable: true,
            inputProps: {
              placeholder: "Email",
            },
            filter: (
              <FilterSearch
                query={filter.data}
                setQuery={filter.setFilter}
                columnName="email"
              />
            ),
            filtering: filter?.data?.email,
          },
          {
            accessor: "status",
            editable: false,
            sortable: true,
            render: (row) => {
              if (!row.status) {
                return (
                  <Badge color="blue" variant="light">
                    New
                  </Badge>
                );
              }
              return (
                <Badge
                  color={row.status !== "bounced" ? "blue" : "red"}
                  variant="light"
                >
                  {row?.status}
                </Badge>
              );
            },

            filter: (
              <StatusFilter query={filter.data} setQuery={filter.setFilter} />
            ),
            filtering: filter?.data?.status,
          },
          {
            accessor: "category",
            render: (row) => {
              const tag = row?.tags?.find(
                (tag) => tag.subtype === "categoryOfUser"
              );

              if (!tag) return null;

              return (
                <Badge color={tag.color} variant="light">
                  {tag.title}
                </Badge>
              );
            },
            editable: true,
            inputProps: {
              placeholder: "Category",
            },
            type: "select",
            options: tags,
            filter: (
              <CategoryFilter
                query={filter.data}
                setQuery={filter.setFilter}
                tags={tags}
                isPending={tagsPending}
              />
            ),
            filtering: filter?.data?.category,
          },
          {
            accessor: "paid",
            width: "0%",
            editable: false,
            render: (row) =>
              row.id !== "temp" && (
                <Group justify="center">
                  <AttendeeSwitch
                    attendeeId={row.id}
                    checked={row.paid}
                    columnName="paid"
                  />
                </Group>
              ),
            sortable: true,
          },
          {
            accessor: "amountPaid",
            width: 200,
            editable: false,
            render: (row) =>
              row.id !== "temp" && (
                <CurrencyInput
                  amountValue={{
                    amountPaid: row.amountPaid || "",
                    currency: row.currency || "RSD",
                  }}
                  attendeeId={row.id}
                />
              ),
          },
          {
            accessor: "isApproved",
            title: "Approve",
            width: "0%",
            sortable: true,
            editable: false,
            render: (row) =>
              row.id !== "temp" && (
                <Group justify="center">
                  <AttendeeSwitch
                    attendeeId={row.id}
                    checked={row.isApproved}
                    columnName="isApproved"
                  />
                </Group>
              ),
          },
          {
            accessor: "actions",
            textAlign: "right",
            width: "0%",
            editable: false,
            render: (row) => (
              <Group justify="right" wrap="nowrap">
                <Tooltip
                  label={`Send Email to ${row.firstName} ${row.lastName}`}
                >
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="green"
                    onClick={() =>
                      actions.openSendEmailModal({ attendeeIds: [row.id] })
                    }
                  >
                    <IconSend2 />
                  </ActionIcon>
                </Tooltip>
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`${row.id}`}
                >
                  <IconEdit />
                </ActionIcon>
                <Tooltip label="Delete">
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="red"
                    onClick={() =>
                      deleteModal(
                        row,
                        `delete ${row.firstName} ${row.lastName}`,
                        deleteAttendee,
                        isError,
                        error,
                        isSuccess
                      )
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        tableProps={{
          pinLastColumn: true,
          page: pagination.data.page,
          onPageChange: (page) =>
            pagination.setPagination({ ...pagination.data, page }),
          sortStatus: sorting.data,
          onSortStatusChange: sorting.setSorting,
          fetching: isPending,
          recordsPerPage: data?.pagination.perPage,
          totalRecords: data?.pagination.total,
          selectedRecords: selectedRecords,
          onSelectedRecordsChange: (newSelectedRecords) => {
            onSelectedRecordsChange(newSelectedRecords);
            const selectedIds = newSelectedRecords.map((record) => record.id);
            updateUrlWithSelectedIds(selectedIds);
          },
        }}
      />
    </>
  );
}

function FilterSearch({ query, setQuery, columnName }) {
  return (
    <TextInput
      label="Attendees"
      description="Show attendees whose names include the specified text"
      placeholder="Search attendees..."
      leftSection={<IconSearch size={16} />}
      rightSection={
        <ActionIcon
          size="sm"
          variant="transparent"
          c="dimmed"
          onClick={() => setQuery(null)}
        >
          <IconX size={14} />
        </ActionIcon>
      }
      value={query?.[columnName] || ""}
      onChange={(e) => {
        if (e.currentTarget.value === "") {
          setQuery(null);
          return;
        }
        setQuery({ [columnName]: e.currentTarget.value });
      }}
    />
  );
}

function CategoryFilter({ query, setQuery, tags, isPending }) {
  if (isPending) {
    <Text>Loading tags...</Text>;
  }

  return (
    <Select
      label="Category"
      description="Filter attendees by category"
      placeholder="Filter by category"
      data={tags}
      value={query?.category}
      onChange={(value) => {
        setQuery({ category: value });
      }}
      onClear={() => setQuery(null)}
      clearable
    />
  );
}

function StatusFilter({ query, setQuery }) {
  return (
    <Select
      label="Status"
      description="Filter attendees by status"
      placeholder="Filter by status"
      data={[
        { value: "new", label: "New" },
        { value: "sent", label: "Sent" },
        { value: "bounced", label: "Bounced" },
        { value: "received", label: "Received" },
        { value: "opened", label: "Opened" },
      ]}
      value={query?.status}
      onChange={(value) => {
        setQuery({ status: value });
      }}
      onClear={() => setQuery(null)}
      clearable
    />
  );
}

export default AttendeesTable;
