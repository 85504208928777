import { Button, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconMail } from "@tabler/icons-react";

import { useSendInviteMail } from "../../api/auth";

export default function SendInviteMailModal({ user }) {
  const { mutate, isSuccess, isError, error } = useSendInviteMail(
    user.publicId
  );
  const alreadyInvited = user.config?.isInvited;

  const openModal = () =>
    modals.openConfirmModal({
      title: `Send invite mail to user [${user.firstName} ${user.lastName}] at [${user.email}]?`,
      children: "Please confirm to proceed.",
      labels: {
        cancel: "Cancel",
        confirm: "Send",
      },
      onConfirm: () => {
        mutate(user);
      },
    });

  return (
    <Tooltip label="Send invite mail">
      <Button
        onClick={openModal}
        variant="light"
        color="cyan"
        icon={<IconMail />}
        size="xs"
        disabled={alreadyInvited}
      >
        {alreadyInvited ? "Email sent" : "Send invite mail"}
      </Button>
    </Tooltip>
  );
}
