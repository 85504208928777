import {
  IconColorSwatch,
  IconEdit,
  IconPlus,
  IconPrinter,
  IconFileImport as IconImport,
  IconMail,
  IconListNumbers,
} from "@tabler/icons-react";

// Function to get query parameters from the URL
const getQueryParams = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const getActionButtonData = (action) => {
  const buildEmailAction = () => {
    const selectedIds = getQueryParams("selectedIds");
    const selectedIdsArray = Array.isArray(selectedIds)
      ? selectedIds
      : [selectedIds];

    if (!selectedIds) {
      return {
        label: "Email",
        icon: IconMail,
        to: "#",
        disabled: true,
      };
    }
    const emailUrl = `?bulkEmail=true&selectedIds=${encodeURIComponent(selectedIdsArray.join(","))}`;
    return {
      label: "Email",
      icon: IconMail,
      to: emailUrl,
      disabled: false,
    };
  };

  switch (action) {
    case "create":
      return { label: "Create", icon: IconPlus, to: "/create" };
    case "edit":
      return { label: "Edit", icon: IconEdit, to: "/edit" };
    case "print":
      return { label: "Print", icon: IconPrinter, to: "/print" };
    case "colorTags":
      return { label: "Color Tags", icon: IconColorSwatch, to: "/colorTags" };
    case "import":
      return { label: "Import", icon: IconImport, to: "/import" };
    case "bulkEmail":
      return buildEmailAction();
    case "levels":
      return { label: "Levels", icon: IconListNumbers, to: "/levels" };
    default:
      return null;
  }
};

export default getActionButtonData;
