const subtypeOptions = (type) => {
  switch (type) {
    case "people":
      return [
        { label: "Category of user", value: "categoryOfUser" },
        { label: "Topic of interest", value: "topicOfInterest" },
      ];
    case "session":
      return [
        { label: "Dress code", value: "dressCode" },
        { label: "Session type", value: "sessionType" },
        { label: "Topic", value: "topic" },
      ];
    default:
      return [
        { label: "Category of user", value: "categoryOfUser" },
        { label: "Topic of interest", value: "topicOfInterest" },
        { label: "Dress code", value: "dressCode" },
        { label: "Session type", value: "sessionType" },
        { label: "Topic", value: "topic" },
      ];
  }
};
export default subtypeOptions;
