export const colorSwatches = [
  // yellows
  "#FFEA00",
  "#FFA500",
  "#FF6500",
  // reds
  "#FF9D9D",
  "#FF0000",
  "#B10000",
  // blues
  "#00C0FF",
  "#0040FF",
  "#102694",
  // greens
  "#00E600",
  "#00B300",
  "#005D00",
  // pinks
  "#FF9DFF",
  "#EB00EB",
  // violets
  "#C58AFF",
  "#7600EB",
  // cyans
  "#00FFEC",
  "#00897F",
  // browns
  "#D2B48C",
  "#3D251E",
  // grays
  "#C5C5C5",
  "#808080",
];
