import { useContext, useState } from "react";
import { AuthContext } from "../services/context";
import { useGetAllAnnouncements } from "../api/announcements";
import { getCurrentWorkspace } from "../services/userConfig";
import AnnouncementsTable from "../components/Announcements/AnnouncementsTable";

function SpecialInvitation() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const { data, isSuccess } = useGetAllAnnouncements({
    type: "special",
    getAll: true,
    eventId,
    ...pagination,
  });

  return (
    <>
      {isSuccess && (
        <AnnouncementsTable
          data={data}
          pagination={{ data: pagination, setPagination }}
          hideTextField
        />
      )}
    </>
  );
}

export default SpecialInvitation;
