import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from "react";

import { useGetEvents } from "../api/event";
import EventsCards from "../components/Events/EventsCard";
import EventsTable from "../components/Events/EventsTable";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";

function Events() {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState({
    columnAccessor: "name",
    direction: "asc",
  });

  const workspaceId = getCurrentWorkspace(user)?.id;

  const isSysAdmin = user.role === "sysAdmin";
  const smallScreen = useMediaQuery("(max-width: 640px)");

  const { data, isPending: isLoading } = useGetEvents({
    workspace: workspaceId,
    sort,
  });

  return (
    <>
      {smallScreen ? (
        <EventsCards
          data={data}
          isLoading={isLoading}
          isSysAdmin={isSysAdmin}
        />
      ) : (
        <EventsTable
          data={data}
          isLoading={isLoading}
          isSysAdmin={isSysAdmin}
          page={page}
          setPage={setPage}
          sortStatus={sort}
          setSort={setSort}
        />
      )}
    </>
  );
}

export default Events;
