import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Flex, Paper, Text } from "@mantine/core";
import { TextInput, Select } from "react-hook-form-mantine";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

import { usePatchTag } from "../../../api/tag";
import subtypeOptions from "../utils/subtypeOptions";

function Form({ data, enableActions }) {
  const { id, title, type, subtype } = data;

  const {
    mutate,
    isSuccess,
    isPending: isLoading,
    isError,
    error,
  } = usePatchTag(id);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      title: title || "",
      type: type || "",
      subtype: subtype || "",
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        type: { type: "string", enum: ["people", "session"] },
        subtype: {
          type: "string",
          enum: [
            "categoryOfUser",
            "topicOfInterest",
            "dressCode",
            "sessionType",
            "topic",
          ],
        },
        title: { type: "string", minLength: 2 },
      },
      required: ["type", "subtype", "title"],
    }),
  });
  const newType = watch("type");
  const newSubtype = watch("subtype");

  useEffect(() => {
    if (dirtyFields.type) {
      setValue("subtype", null);
    }
  }, [dirtyFields, setValue]);

  const enableCategoryOfUserSelect =
    newType === "people" && newSubtype === "categoryOfUser";

  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      title:
        data.subtype === "categoryOfUser" ? data.categoryOfUser : data.title,
    };

    mutate(updatedData);
  };
  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            label="Type"
            placeholder="Type"
            defaultValue={type}
            name="type"
            withAsterisk
            control={control}
            clearable
            data={[
              { label: "People", value: "people" },
              { label: "Session", value: "session" },
            ]}
            mt="md"
          />
          {newType && (
            <Select
              label="Subtype"
              placeholder="Select type to see subtype options"
              defaultValue={subtype}
              name="subtype"
              withAsterisk
              control={control}
              data={subtypeOptions(newType)}
              mt="md"
              clearable
            />
          )}
          {enableCategoryOfUserSelect ? (
            <Select
              label="Title"
              placeholder="Category of user"
              name="categoryOfUser"
              defaultValue={title}
              withAsterisk
              control={control}
              data={[
                { label: "Organizer", value: "organizer" },
                { label: "Sponsor", value: "sponsor" },
                { label: "Speaker", value: "speaker" },
                { label: "Attendee", value: "attendee" },
              ]}
              mt="md"
            />
          ) : (
            <TextInput
              name="title"
              control={control}
              defaultValue={title}
              label="Title"
              withAsterisk
              mt="md"
            />
          )}

          {isError && (
            <Text c="red" size="sm" mt="md">
              {error.message.includes("Duplicate")
                ? "A tag with this title already exists. Please choose a different title."
                : "An error occurred: " + error.message}
            </Text>
          )}

          <Flex justify="space-between" mt="md">
            <Button
              type="submit"
              loading={isLoading}
              disabled={!enableActions || isSuccess}
            >
              Edit tag
            </Button>
          </Flex>
        </form>
      </Paper>
    </Container>
  );
}

export default Form;
