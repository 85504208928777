import { Button, Paper } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";
import IconSelect from "../../IconSelect";
import PagesEditor from "../PagesEditor";
import { usePatchPage } from "../../../api/pages";
import { extractDirtyData } from "../../../utils/dirtyData";

const EditPageForm = ({ data }) => {
  // convert boolean to string
  const defaultValues = {
    title: data?.title,
    icon: data?.icon,
    content: data?.content,
    isDraft: data?.isDraft ? "true" : "false",
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
    watch,
  } = useForm({
    defaultValues,
  });

  const { mutate, isPending } = usePatchPage(data.id);

  const icon = watch("icon");

  const onSubmit = (data) => {
    if (data.isDraft) {
      data.isDraft = data.isDraft === "true";
    }

    const dirtyData = extractDirtyData(data, dirtyFields);

    mutate(dirtyData, { onSuccess: reset({ ...defaultValues, ...dirtyData }) });
  };

  return (
    <Paper withBorder shadow="md" p="sm" mt="sm" radius="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Page title"
          placeholder="Sample page"
          control={control}
          name="title"
          mt="sm"
        />
        <IconSelect
          label="Icon"
          placeholder="Select icon"
          control={control}
          name="icon"
          currentIcon={icon}
          mt="sm"
        />
        <Select
          label="Page status"
          placeholder="Select page status"
          control={control}
          name="isDraft"
          data={[
            { value: "true", label: "Draft" },
            { value: "false", label: "Published" },
          ]}
          mt="sm"
        />
        <Controller
          name="content"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PagesEditor onChange={onChange} content={JSON.parse(value)} />
          )}
        />
        <Button type="submit" disabled={!isDirty} loading={isPending}>
          Update page
        </Button>
      </form>
    </Paper>
  );
};

export default EditPageForm;
