import { useForm } from "react-hook-form";
import {
  TextInput,
  Textarea,
  SegmentedControl,
  Select,
} from "react-hook-form-mantine";
import { EvaIcon } from "../EvaIcon";
import { Paper, Button, Center } from "@mantine/core";
import { useUpdateAnnouncement } from "../../api/announcements";
import { modals } from "@mantine/modals";
import { extractDirtyData } from "../../utils/dirtyData";
import moreThanOneAnnouncementModal from "./MoreThanOneAnnouncementModal";

const EditAnnouncementForm = ({ data, moreThanOneVisible, tags }) => {
  const defaultValues = {
    title: data.title,
    text: data.text,
    type: data.type,
    isVisible: data.isVisible ? "true" : "false",
    eventId: data.eventId,
    targetedTagId: data.targetedTagId ? String(data.targetedTagId) : "",
  };

  const tagsOptions = [
    { value: "", label: "All attendees" },
    ...(tags ?? []).map((tag) => ({
      value: String(tag.id),
      label: `${tag.title} - (${tag.usageCount} users)`,
    })),
  ];

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues,
  });

  const { mutate, isPending } = useUpdateAnnouncement(data.id);

  const onSubmit = (data) => {
    if (data.isVisible) {
      data.isVisible = data.isVisible === "true";
    }

    const dirtyData = extractDirtyData(data, dirtyFields);

    if (dirtyData.isVisible && moreThanOneVisible) {
      const showBothCallback = () => {
        mutate(dirtyData, {
          onSuccess: () => {
            reset({ ...defaultValues, ...dirtyData });
            modals.closeAll();
          },
        });
      };

      const showOnlyThisCallback = () => {
        mutate(
          { ...dirtyData, isOnlyThisVisible: true },
          {
            onSuccess: () => {
              reset({ ...defaultValues, ...dirtyData });
              modals.closeAll();
            },
          }
        );
      };

      moreThanOneAnnouncementModal(showBothCallback, showOnlyThisCallback);
    } else {
      mutate(dirtyData, {
        onSuccess: reset({ ...defaultValues, ...dirtyData }),
      });
    }
  };

  return (
    <Paper withBorder shadow="md" p={16} mt={16} radius="md">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <TextInput
          label="Title"
          mt="sm"
          name="title"
          control={control}
          placeholder="Your title"
        />
        <Textarea
          label="Text"
          placeholder="Enter description text"
          rows={2}
          mt="sm"
          name="text"
          control={control}
        />
        <Select
          label="Targeted announcement"
          description="Select tag to target users"
          placeholder="Select targeted tag"
          mt="sm"
          name="targetedTagId"
          control={control}
          data={tagsOptions}
          allowDeselect={false}
        />
        <SegmentedControl
          name="type"
          mt="sm"
          control={control}
          data={[
            {
              value: "info",
              label: (
                <Center style={{ gap: 10 }}>
                  <EvaIcon name="info-outline" />
                  <span>Info</span>
                </Center>
              ),
            },
            {
              value: "warning",
              label: (
                <Center style={{ gap: 10 }}>
                  <EvaIcon name="alert-triangle-outline" />
                  <span>Warning</span>
                </Center>
              ),
            },
          ]}
          fullWidth
        />
        <Select
          label="Visibility"
          placeholder="Select visibility"
          mt="sm"
          name="isVisible"
          control={control}
          data={[
            { label: "Visible", value: "true" },
            { label: "Hidden", value: "false" },
          ]}
        />
        <Button type="submit" mt="sm" loading={isPending} disabled={!isDirty}>
          Edit announcement
        </Button>
      </form>
    </Paper>
  );
};

export default EditAnnouncementForm;
