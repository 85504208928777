import { AppShell, Burger, Flex } from "@mantine/core";

import Breadcrumbs from "../Breadcrumbs";
import ActionButtons from "./ActionButtons";
import { PageTitle } from "./PageTitle";

function Header({ opened, toggle }) {
  return (
    <AppShell.Header
      height={60}
      p="md"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 10,
      }}
    >
      <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
      <Flex style={{ width: "100%" }} justify="space-between" align="center">
        <Flex direction="column">
          <Breadcrumbs />
          <PageTitle />
        </Flex>
        <ActionButtons />
      </Flex>
    </AppShell.Header>
  );
}

export default Header;
