import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  FileInput,
  Flex,
  Group,
  Image,
  Paper,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { IconFileUpload, IconPlus, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { MultiSelect, Select, TextInput } from "react-hook-form-mantine";
import { useUpload } from "../../../api/r2";
import { usePatchSponsor } from "../../../api/sponsors";
import { toBase64 } from "../../../services/helper";

function EditForm({ data, levelsData, sponsorReps, attendeesData }) {
  const { id } = data;
  const [sponsorLogo, setSponsorLogo] = useState(data.logo);

  const { mutate, isPending: isLoading, isSuccess } = usePatchSponsor(id);

  const levelOptions = levelsData.map((level) => ({
    value: level.id.toString(),
    label: level.name,
  }));

  const defaultLevel = levelOptions.find(
    (option) => option.value === data.levelId.toString()
  );

  // Options for representatives is in attendeesData
  const repOptions = attendeesData?.map((attendee) => {
    const fullName = `${attendee.firstName} ${attendee.lastName}`;
    const occupation = attendee.occupation ? ` , ${attendee.occupation}` : "";

    return {
      value: attendee.id.toString(),
      label: `${fullName}${occupation}`,
    };
  });

  const defaultReps =
    sponsorReps?.map((rep) => {
      const fullName = `${rep.firstName} ${rep.lastName}`;
      const occupation = rep.occupation ? ` , ${rep.occupation}` : "";

      return {
        value: rep.id.toString(),
        label: `${fullName}${occupation}`,
      };
    }) || [];

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: data.name || "",
      levelId: defaultLevel?.value || "",
      description: data.description || "",
      logo: data.logo || "",
      links: data.links || [],
      repIds: defaultReps.map((rep) => rep.label),
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 3 },
      },
    }),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const { mutate: upload } = useUpload();

  const onSubmit = (data) => {
    if (dirtyFields.logo) {
      data.logo = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${sponsorLogo.name}`;
    }

    data.repIds = data.repIds.map((label) => {
      const selectedRep = repOptions.find(
        (option) => option.label === label || option.value === label
      );
      return selectedRep.value;
    });
    mutate(data);
  };

  const currentLogo = getValues("logo");

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
          />
          <Select
            label="Sponsor level"
            placeholder="Select sponsor level"
            name="levelId"
            data={levelOptions}
            control={control}
            mt="md"
            withAsterisk
          />
          <TextInput
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
          />

          <Group>
            <FileInput
              label="Sponsor logo"
              name="logo"
              placeholder={
                data.logo ? data.logo.split("/").at(-1) : "Upload file"
              }
              clearable
              rightSection={<IconFileUpload />}
              accept="image/png,image/jpeg"
              mt="md"
              style={{ flexGrow: 1 }}
              onChange={(value) => {
                setSponsorLogo(value);

                setValue(
                  "logo",
                  `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${value.name}`,
                  { shouldDirty: true }
                );
              }}
            />
            <Image src={currentLogo} w={80} ml={10} fit="contain" />
          </Group>
          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link label"
                  {...register(`links.${index}.label`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}
            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          <MultiSelect
            label="Representative"
            name="repIds"
            data={repOptions}
            control={control}
            placeholder={
              defaultReps.length ? "" : "Select sponsor representative"
            }
            mt="md"
            description={
              repOptions.length === 0
                ? "No representatives available. Please create attendees for this event."
                : ""
            }
            searchable
          />

          <Flex justify="space-between">
            <Button
              type="submit"
              mt="md"
              loading={isLoading}
              disabled={!isDirty || isSuccess}
              onClick={async () => {
                if (!dirtyFields.logo) {
                  return;
                }

                upload({
                  name: sponsorLogo.name,
                  data: await toBase64(sponsorLogo),
                });
              }}
            >
              Edit sponsor
            </Button>
          </Flex>
        </form>
      </Paper>
    </Container>
  );
}

export default EditForm;
