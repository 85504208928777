import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetPages = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["pages", params] },
    fetchOptions: { url: "/pages", method: "GET", params },
  });

export const useGetPage = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["page", { id }], enabled: !!id },
    fetchOptions: { url: `/pages/${id}`, method: "GET" },
  });

export const usePatchPage = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "usePatchPage" },
    fetchOptions: { url: `/pages/${id}`, method: "PATCH" },
    invalidateQueryKey: "pages",
    successMessage: { title: "Page has been updated", color: "green" },
  });

export const useCreatePage = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreatePage" },
    fetchOptions: { url: "/pages", method: "POST" },
    invalidateQueryKey: "pages",
    successMessage: { title: "Page has been created", color: "green" },
  });

export const useDeletePage = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeletePage" },
    fetchOptions: { url: `/pages/${id}`, method: "DELETE" },
    invalidateQueryKey: "pages",
    successMessage: { title: "Page has been deleted", color: "green" },
  });
