import { useParams } from "react-router-dom";
import { useGetAttendee, useUpdateAttendee } from "../../api/attendees";
import { useGetUser } from "../../api/user";
import { EditUser } from "../Users/EditUser";
import { Button, Container, Fieldset } from "@mantine/core";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { useForm } from "react-hook-form";
import { Select } from "react-hook-form-mantine";
import { capitalize } from "../../utils/textUtils";

function Edit() {
  const { attendeeId } = useParams();

  const { data: attendee } = useGetAttendee({ attendeeId });

  const { data: user } = useGetUser(attendee?.userId);

  return (
    <>
      {user && <EditUser user={user} />}
      {attendee && <EditAttendeeTag attendee={attendee} />}
    </>
  );
}

function EditAttendeeTag({ attendee }) {
  const { data } = useGetPeopleTagsBySubtype({
    eventId: attendee.eventId,
    subtype: "categoryOfUser",
  });

  const findAttendeeCategory = attendee.tags.find(
    (tag) => tag.subtype === "categoryOfUser"
  );

  const { mutate, isPending } = useUpdateAttendee({ attendeeId: attendee.id });

  const defaultValues = {
    categoryOfUser: findAttendeeCategory?.id
      ? String(findAttendeeCategory.id)
      : "",
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues,
  });

  const handleTagUpdate = (data) => {
    mutate(
      {
        categoryOfUser: data.categoryOfUser,
      },
      {
        onSuccess: () => {
          reset({ categoryOfUser: data.categoryOfUser });
        },
        onError: () => {
          // Handle error
          reset({ ...defaultValues });
        },
      }
    );
  };

  return (
    <Container my="md">
      <form onSubmit={handleSubmit((data) => handleTagUpdate(data))}>
        <Fieldset legend="Attendee category">
          <Select
            label="Category"
            placeholder="Select category"
            data={data?.map((tag) => ({
              value: String(tag.id),
              label: capitalize(tag.title),
            }))}
            control={control}
            name="categoryOfUser"
          />
        </Fieldset>
        <Button
          type="submit"
          variant="light"
          color="blue"
          mt="md"
          disabled={!isDirty}
          loading={isPending}
        >
          Change category
        </Button>
      </form>
    </Container>
  );
}

export default Edit;
