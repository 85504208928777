export function formatTagLabel(tag) {
  if (!tag) return "";

  // replaces camelCase with spaces before uppercase letters and capitalize the first letter
  const formatted = tag
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());

  return formatted;
}
