import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Container,
  Paper,
  Title,
  Text,
  TagsInput,
  Flex,
  Badge,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { Select } from "react-hook-form-mantine";
import { useContext, useEffect } from "react";
import { IconInfoCircle } from "@tabler/icons-react";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreateTag, useGetTagColors } from "../../api/tag";
import { createTagSchema } from "./schema";
import subtypeOptions from "./utils/subtypeOptions";
import defaultColors from "./utils/defaultColors";

function CreateTag() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { mutate, isPending, isSuccess, isError, error } = useCreateTag();
  const { data: tagColors } = useGetTagColors(currentEventId);

  const {
    control,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      subtype: "",
      title: [],
      categoryOfUser: [],
    },
    resolver: zodResolver(createTagSchema),
  });

  const type = watch("type");
  const subtype = watch("subtype");
  const isCategoryOfUserSubtype =
    type === "people" && subtype === "categoryOfUser";

  useEffect(() => {
    if (!type) {
      resetField("subtype");
    }
  }, [type, resetField]);

  const previewColor =
    (Array.isArray(tagColors) &&
      tagColors?.find((color) => color.subtype === subtype)?.color) ||
    defaultColors.find((color) => color.subtype === subtype)?.color;

  const onSubmit = (data) => {
    // Clear previous errors
    clearErrors();

    const newData = {
      ...data,
      eventId: currentEventId,
    };

    // Validate title field before submitting
    if (!newData.title.length) {
      setError("title", { type: "manual", message: "Title is required." });
      return;
    }

    mutate(newData);
  };

  return (
    <Container size={500}>
      <Title>Create tag</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            label="Type"
            placeholder="Type"
            name="type"
            withAsterisk
            control={control}
            clearable
            data={[
              { label: "People", value: "people" },
              { label: "Session", value: "session" },
            ]}
            mt="md"
          />
          {type && (
            <Select
              label="Subtype"
              placeholder="Select type to see subtype options"
              name="subtype"
              withAsterisk
              control={control}
              data={subtypeOptions(type)}
              mt="md"
            />
          )}

          {type && subtype && (
            <>
              <Paper shadow="md" p="sm" withBorder mt="xs">
                <Flex
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: 13, paddingInlineEnd: 5 }}>
                    Preview of color for subtype selected:
                  </Text>
                  <Badge color={previewColor} variant="light">
                    tag
                  </Badge>
                </Flex>
                <Text style={{ fontSize: 13 }}>
                  Configure color for subtypes of tags in Color tags section.
                </Text>
              </Paper>
              {isCategoryOfUserSubtype && (
                <Paper shadow="md" p="sm" withBorder mt="xs">
                  <Flex style={{ alignItems: "center" }}>
                    <IconInfoCircle />
                    <Container>
                      <Text style={{ fontSize: 13 }}>
                        Default category of user tags are already created:
                      </Text>
                      {["Attendee", "Speaker", "Sponsor", "Organizer"].map(
                        (tag) => (
                          <Badge
                            color={previewColor}
                            variant="light"
                            key={tag}
                            mr={5}
                          >
                            {tag}
                          </Badge>
                        )
                      )}
                    </Container>
                  </Flex>
                </Paper>
              )}
            </>
          )}

          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TagsInput
                {...field}
                label="Title"
                placeholder="Title"
                description="Enter title and press enter to add tag"
                withAsterisk
                mt="md"
              />
            )}
          />

          {/* Show form validation errors */}
          {errors.title && (
            <Text c="red" size="sm" mt="md">
              {errors.title.message}
            </Text>
          )}
          {/* Show api errors */}
          {isError && (
            <Text c="red" size="sm" mt="md">
              {error.message.includes("Duplicate")
                ? "A tag with this title already exists. Please choose a different title."
                : "An error occurred: " + error.message}
            </Text>
          )}

          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={isSuccess}
          >
            Create tag
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateTag;
