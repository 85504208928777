import { modals } from "@mantine/modals";
import { DatePicker } from "@mantine/dates";
import { useState } from "react";
import { Button, Flex } from "@mantine/core";
import { usePatchEvent } from "../../../api/event";
import dayjs from "dayjs";

function EventDatesModal({ event }) {
  const initialDates = event?.dates.map((date) => new Date(date.date));

  const [dates, setDates] = useState(initialDates);

  const { mutate } = usePatchEvent(event?.id);

  const handleSave = () => {
    // For each date, we need to convert them to date string
    const data = dates.map((date) => {
      // Date should be in the format of "YYYY-MM-DD"
      // We're using dayjs to format the date
      // because it's easier to work with than the native Date object
      return dayjs(date).format("YYYY-MM-DD");
    });

    mutate({ dates: data }, { onSuccess: () => modals.closeAll() });
  };

  return (
    <Flex direction="column" gap="md" align="center">
      <DatePicker
        type="multiple"
        value={dates}
        onChange={(value) => {
          setDates(value);
        }}
      />
      <Button
        onClick={() => {
          handleSave();
        }}
      >
        Save
      </Button>
    </Flex>
  );
}

export const openEventDatesModal = (event) => {
  modals.open({
    title: "Select each event date",
    children: <EventDatesModal event={event} />,
  });
};

export default EventDatesModal;
