import * as eva from "eva-icons";
import { EvaIcon } from "./EvaIcon";
import { Select } from "react-hook-form-mantine";
import { Group, Text, Flex } from "@mantine/core";

const IconSelect = ({ currentIcon, ...props }) => {
  const evaIcons = eva.icons;

  const renderIconOption = ({ option }) => {
    return (
      <Group>
        <EvaIcon name={option.value} />
        <Text>{option.label}</Text>
      </Group>
    );
  };

  return (
    <Flex align="center" gap="xs">
      <EvaIcon
        name={currentIcon}
        styles={{ root: { marginTop: 24, paddingLeft: 8 } }}
      />
      <Select
        style={{ flex: 1 }}
        label="Icon"
        placeholder="Select icon"
        searchable
        data={Object.keys(evaIcons).map((icon) => {
          return {
            value: icon,
            label: icon
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
          };
        })}
        renderOption={renderIconOption}
        {...props}
      />
    </Flex>
  );
};

export default IconSelect;
