import { Loader, Text } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  useGetSponsor,
  useGetSponsorLevels,
  useGetSponsorReps,
} from "../../../api/sponsors";
import { useGetAllAttendees } from "../../../api/attendees";
import { getCurrentWorkspace } from "../../../services/userConfig";
import { AuthContext } from "../../../services/context";
import EditForm from "./Form";

function EditSponsors() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();

  const { eventId } = getCurrentWorkspace(user);

  const isSysAdmin = user.role === "sysAdmin";

  const {
    data: responseData,
    isPending: isLoading,
    isError,
    error,
  } = useGetSponsor(id);

  const {
    data: levelsData,
    isPending: levelPending,
    isError: levelsIsError,
    error: levelsError,
  } = useGetSponsorLevels({
    eventId,
  });

  const {
    data: sponsorRepsData,
    isPending: repPending,
    isError: repsIsError,
    error: repsError,
  } = useGetSponsorReps({
    eventId,
  });

  const {
    data: attendeesData,
    isPending: attendeePending,
    isError: attendeeIsError,
    error: attendeeError,
  } = useGetAllAttendees({
    eventId,
  });

  const isPending = isLoading || levelPending || repPending || attendeePending;
  const hasError = isError || levelsIsError || repsIsError || attendeeIsError;
  const errorMessage = error || levelsError || repsError || attendeeError;

  if (isPending) {
    return <Loader mt={10} />;
  }

  if (hasError) {
    return <Text c="red">{errorMessage.message}</Text>;
  }

  if (responseData && levelsData && sponsorRepsData && attendeesData) {
    const myReps = sponsorRepsData.filter(
      (rep) => rep.sponsorId === Number(id)
    );

    return (
      <EditForm
        data={responseData}
        isSysAdmin={isSysAdmin}
        levelsData={levelsData}
        sponsorReps={myReps}
        attendeesData={attendeesData.data}
      />
    );
  }
}

export default EditSponsors;
