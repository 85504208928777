import { ActionIcon, Group, Avatar, TextInput } from "@mantine/core";
import { IconEdit, IconSearch, IconX } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import DeleteSpeakerModal from "./Delete";
import { DataTable } from "../DataTable";

function Table({ data, pagination, isPending, sort, setSort, filter }) {
  return (
    <DataTable
      columns={[
        {
          accessor: "picture",
          render: (row) => (
            <Avatar
              src={row.picture}
              size="md"
              radius="sm"
              alt="avatar"
              color="initials"
              name={`${row.firstName} ${row.lastName}`}
            />
          ),
          align: "center",
          width: 75,
        },
        {
          accessor: "firstName",
          title: "First Name",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter?.data}
              setQuery={filter?.setFilter}
              columnName="firstName"
              label="Search by first name"
            />
          ),
          filtering: filter?.data?.firstName,
        },
        {
          accessor: "lastName",
          title: "Last Name",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter?.data}
              setQuery={filter?.setFilter}
              columnName="lastName"
              label="Search by last name"
            />
          ),
          filtering: filter?.data?.lastName,
        },
        {
          accessor: "occupation",
          title: "Occupation",
          filter: (
            <FilterSearch
              query={filter?.data}
              setQuery={filter?.setFilter}
              columnName="occupation"
              label="Search by occupation"
            />
          ),
          filtering: filter?.data?.occupation,
          sortable: true,
        },
        {
          accessor: "actions",
          width: 150,
          render: (row) => (
            <Group>
              <ActionIcon
                component={Link}
                to={`/app/speakers/${row.id}`}
                variant="light"
                color="blue"
              >
                <IconEdit />
              </ActionIcon>
              <DeleteSpeakerModal speakerId={row.id} />
            </Group>
          ),
        },
      ]}
      records={data?.data}
      fetching={isPending}
      page={pagination.data.page}
      onPageChange={(page) =>
        pagination.setPagination({ ...pagination.data, page })
      }
      sortStatus={sort}
      onSortStatusChange={setSort}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
    />
  );
}

export default Table;

function FilterSearch({
  query,
  setQuery,
  columnName,
  label,
  description,
  placeholder,
}) {
  return (
    <TextInput
      label={label || "Search"}
      description={description || `Search by ${columnName}`}
      placeholder={placeholder}
      leftSection={<IconSearch size={16} />}
      rightSection={
        <ActionIcon
          size="sm"
          variant="transparent"
          c="dimmed"
          onClick={() => setQuery(null)}
        >
          <IconX size={14} />
        </ActionIcon>
      }
      value={query?.[columnName] || ""}
      onChange={(e) => {
        if (e.currentTarget.value === "") {
          setQuery(null);
          return;
        }
        setQuery({ [columnName]: e.currentTarget.value });
      }}
    />
  );
}
