export function formatDateTime(dateTime) {
  // Mon Apr 29 2024 00:00:00 GMT+0200 (Central European Summer Time) => '2024-04-29 00:00:00'
  const date = new Date(dateTime);
  // Add leading zero if it is a single digit
  const formattedDate = (value) =>
    value.toString().length === 1 ? `0${value}` : value;

  const year = date.getFullYear();
  const month = formattedDate(date.getMonth() + 1);
  const day = formattedDate(date.getDate());
  const hours = formattedDate(date.getHours());
  const minutes = formattedDate(date.getMinutes());

  return `${year}-${month}-${day} ${hours}:${minutes}:00`;
}
