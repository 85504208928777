import { AppShell } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useDisclosure, useDocumentTitle } from "@mantine/hooks";
import { Outlet } from "react-router-dom";

import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import { AuthContext } from "../services/context";

export default function App() {
  const { user } = useContext(AuthContext);
  const isAuthenticated = !!user;
  const [opened, { toggle }] = useDisclosure();
  const [documentTitle, setTitle] = useState("CONGRS");
  useDocumentTitle(documentTitle);

  // Set the document title to "CONGRS - DEV" in development environments
  // to make it easier to distinguish between production and development
  // because I have a habit of opening multiple environments in the browser.
  useEffect(() => {
    if (import.meta.env.NODE_ENV !== "production") {
      setTitle("CONGRS - DEV");
    }
  }, []);

  return (
    <AppShell
      navbar={{
        width: 280,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      header={{
        height: 60,
      }}
      layout="alt"
    >
      <Header opened={opened} toggle={toggle} />
      <Navbar
        opened={opened}
        toggle={toggle}
        user={user}
        isAuthenticated={isAuthenticated}
      />

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
