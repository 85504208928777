const displayChangeStatusText = (change, changeStatus) => {
  // Returns the status of the change
  const lastChange = change[change.length - 1];
  if (changeStatus === "approved" && lastChange.newValue) {
    return "Approved";
  } else if (changeStatus === "approved" && !lastChange.newValue) {
    return "Not approved";
  } else {
    return "Updated";
  }
};

const displayChange = (change) => {
  // Formats the change to be displayed
  switch (change.field) {
    case "isApproved":
      return `Approve ${change.previousValue} -> Approve ${change.newValue}`;
    case "paid":
      return change.previousValue ? "Paid -> No payment" : "No payment -> Paid";
    case "amountPaid":
      return change.newValue !== 0
        ? `Payment amount: ${change.previousValue} -> ${change.newValue}`
        : null;
    case "currency":
      return `Currency: ${change.previousValue} -> ${change.newValue}`;
    default:
      return "Unknown change";
  }
};

const getTextStatus = (changeStatusText) => {
  // Returns the status property of the text component based on the change status
  switch (changeStatusText) {
    case "Approved":
      return "green";
    case "Updated":
      return "orange";
    case "Not approved":
      return "red";
    default:
      return null;
  }
};

export { displayChangeStatusText, displayChange, getTextStatus };
