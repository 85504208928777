import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../services/context";
import LoadingPage from "../common/LoadingPage";
import { useGoogleLogin } from "../../api/auth";

function GoogleSignIn({ data }) {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const {
    mutate,
    data: userData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGoogleLogin();

  useEffect(() => {
    mutate({ token: data });
  }, [mutate, data]);

  useEffect(() => {
    if (isLoading) {
      return <LoadingPage />;
    }
    if (isError) {
      console.log("Google login error", error);
      navigate("/");
    }
    if (isSuccess && userData) {
      localStorage.setItem("token", userData.token);
      setUser({ ...userData.user, picture: userData.user.picture });
      navigate("/app");
    }
  }, [isError, isSuccess, isLoading, data, setUser, navigate, error, userData]);
}

export default GoogleSignIn;
