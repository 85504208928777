import {
  ActionIcon,
  Badge,
  Group,
  Switch,
  Text,
  Tooltip,
  Radio,
  Button,
  Stack,
} from "@mantine/core";
import { IconSwitchVertical, IconEye } from "@tabler/icons-react";
import { DataTable } from "../DataTable";
import { Link } from "react-router-dom";
import { useState } from "react";
import { modals } from "@mantine/modals";
import { usePollsToggle } from "../../api/session";
import { useUpdatePollStatus } from "../../api/polls";
import { useEffect } from "react";
import { displayDateTime } from "../Sessions/utils/displayDateTime";

const StatusForm = ({ sessionId }) => {
  const [value, setValue] = useState("open");
  const { mutate, isPending, isSuccess } = useUpdatePollStatus({
    sessionId,
    status: value,
  });
  useEffect(() => {
    if (isSuccess) {
      modals.closeAll();
    }
  }, [isSuccess]);
  return (
    <>
      <p style={{ marginTop: 0, fontSize: 15 }}>
        Set all the polls for this session to have the following status:
      </p>
      <Radio.Group value={value} onChange={setValue}>
        <Stack gap="sm">
          <Radio value="open" label="Opened" />
          <Radio value="closed" label="Closed" />
        </Stack>
      </Radio.Group>
      <Button
        disabled={isPending}
        fullWidth
        onClick={() => {
          mutate({ sessionId, status: value });
        }}
        mt="md"
      >
        Submit
      </Button>
      <Button variant="white" fullWidth onClick={() => modals.closeAll()}>
        Cancel
      </Button>
    </>
  );
};

const PollToggleSwitch = ({ sessionId, defaultChecked }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const { mutate: switchMutate } = usePollsToggle();

  const handleTogglePolls = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    switchMutate(sessionId, {
      onError: () => {
        setIsChecked(defaultChecked);
      },
    });
  };

  return (
    <Switch
      color="green"
      defaultChecked={defaultChecked}
      onLabel="ON"
      checked={isChecked}
      offLabel="OFF"
      onChange={() => handleTogglePolls()}
    />
  );
};

export default function SessionsTable({
  data,
  isLoading,
  role,
  page,
  setPage,
  sortStatus,
  setSort,
}) {
  const handleChangePollStatus = (sessionId) => {
    modals.open({
      children: <StatusForm sessionId={sessionId} />,
    });
  };

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "title",
          title: "Session Title",
          render: (session) => (
            <>
              <Text size="sm">{session.title}</Text>
              {session.canceledAt !== null && (
                <Badge color="red" variant="light" size="sm">
                  Canceled
                </Badge>
              )}
            </>
          ),
        },
        {
          accessor: "starting",
          title: "Starting Time",
          render: (session) => displayDateTime(session.starting),
          sortable: true,
        },
        {
          accessor: "ending",
          title: "Ending Time",
          render: (session) => displayDateTime(session.ending),
        },
        { accessor: "pollsCount", title: "Number of Polls" },
        {
          accessor: "activePolls",
          title: "Show Polls on Mobile Homepage",
          render: (session) => (
            <PollToggleSwitch
              sessionId={session.id}
              defaultChecked={session.activePolls}
            />
          ),
        },
        {
          accessor: "actions",
          width: 100,
          render: (session) => (
            <Group justify="center">
              <Tooltip label="View">
                <ActionIcon size="md" component={Link} to={`${session.id}`}>
                  <IconEye />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Change polls status">
                <ActionIcon size="md">
                  <IconSwitchVertical
                    onClick={() => handleChangePollStatus(session.id)}
                  />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
      sortStatus={sortStatus}
      onSortStatusChange={setSort}
    />
  );
}
