import { useApiQuery, useApiMutation } from "../services/react-query";

export const useGetTags = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["tags", params], enabled: !!params.eventId },
    fetchOptions: { url: "/tags", method: "GET", params },
  });

export const useCreateTag = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateTag" },
    fetchOptions: { url: "/tags", method: "POST" },
    invalidateQueryKey: "tags",
  });

export const useGetTagColors = (eventId) =>
  useApiQuery({
    queryOptions: { queryKey: ["tagColors", eventId] },
    fetchOptions: { url: `/tagColors/${eventId}`, method: "GET" },
  });

export const useUpdateTagColor = (data) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useUpdateTagColor" },
    fetchOptions: { url: `/tagColors/update`, method: "PATCH" },
    invalidateQueryKey: "tagColors",
    body: { data },
  });

export const useResetTagColor = (eventId) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useResetTagColor" },
    fetchOptions: { url: `/tagColors/reset`, method: "DELETE" },
    invalidateQueryKey: "tagColors",
    body: { eventId },
  });

export const useGetTagById = (tagId) =>
  useApiQuery({
    queryOptions: { queryKey: ["tags", tagId] },
    fetchOptions: { url: `/tags/${tagId}`, method: "GET" },
  });

export const usePatchTag = (tagId) =>
  useApiMutation({
    mutationOptions: { mutationKey: "usePatchTag" },
    fetchOptions: { url: `/tags/${tagId}`, method: "PATCH" },
    invalidateQueryKey: ["tags", tagId],
  });

export const useDeleteTag = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteTag" },
    fetchOptions: { url: `/tags`, method: "DELETE" },
    invalidateQueryKey: ["tags"],
    errorMessages: {
      ER_ROW_IS_REFERENCED_2: "Cannot delete tag that is in use.",
    },
    successMessage: { title: "Tag deleted successfully." },
  });

export const useGetSessionTagsByEventId = (eventId) =>
  useApiQuery({
    queryOptions: { queryKey: ["sessionTags", eventId] },
    fetchOptions: { url: `/tags/session/event/${eventId}`, method: "GET" },
  });

export const useGetSessionTagsBySessionId = (sessionId) =>
  useApiQuery({
    queryOptions: { queryKey: ["sessionTags", sessionId] },
    fetchOptions: { url: `/tags/session/${sessionId}`, method: "GET" },
  });

export const useGetPeopleTagsBySubtype = ({ subtype, eventId }) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["peopleTags", subtype, eventId],
      enabled: !!subtype,
    },
    fetchOptions: {
      url: `/tags/people/subtypes/${eventId}?subtypes=["${subtype}"]`,
      method: "GET",
    },
  });
};
