import { Switch } from "@mantine/core";
import { useState } from "react";
import { useUpdateAttendee } from "../../api/attendees";

function AttendeeSwitch({ checked, attendeeId, columnName }) {
  const [isChecked, setIsChecked] = useState(checked);

  const { mutate } = useUpdateAttendee({ attendeeId });

  if (!columnName) {
    return null;
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    mutate(
      { [columnName]: !isChecked },
      { onError: () => setIsChecked(isChecked) }
    );
  };

  return (
    <Switch
      color="green"
      checked={isChecked}
      onChange={() => {
        handleCheckboxChange();
      }}
    />
  );
}

export default AttendeeSwitch;
