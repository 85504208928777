import { ActionIcon, Badge, Group, Text, Tooltip } from "@mantine/core";
import { IconBookmark, IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { DataTable } from "../DataTable";
import { useDeleteSession } from "../../api/session";
import { useGetSponsors } from "../../api/sponsors";
import deleteModal from "../deleteModal";
import { displayDateTime } from "./utils/displayDateTime";
import { useGetSessionTagsByEventId, useGetTagColors } from "../../api/tag";
import defaultColors from "../Tags/utils/defaultColors";
import ExpandedRow from "./ExpandedRow";

export default function SessionsTable({
  data,
  isLoading,
  role,
  page,
  setPage,
  sortStatus,
  setSort,
  eventId,
}) {
  const enableActions = role === "sysAdmin" || role === "wsAdmin";
  const { mutate, isError, isSuccess, error } = useDeleteSession();
  const { data: sponsorsData } = useGetSponsors();
  const { data: tagsData } = useGetSessionTagsByEventId(eventId);
  const { data: tagColorsData } = useGetTagColors(eventId);

  const findColor = (colors, subtype) =>
    colors.find((color) => color.subtype === subtype);

  const getTagColor = (tagColors, defaultColors, subtype) => {
    if (tagColors.data === "default") {
      return findColor(defaultColors, subtype);
    }
    return findColor(tagColors, subtype) || findColor(defaultColors, subtype);
  };

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "title",
          title: "Title",
          render: (session) => (
            <>
              <Text size="sm">{session.title}</Text>
              {session.canceledAt !== null && (
                <Badge color="red" variant="light" size="sm">
                  Canceled
                </Badge>
              )}
            </>
          ),
        },
        {
          accessor: "starting",
          sortable: true,
          render: (session) => displayDateTime(session.starting),
        },
        {
          accessor: "ending",
          render: (session) => displayDateTime(session.ending),
        },
        { accessor: "location", render: (row) => row.location?.name },
        {
          accessor: "sponsorId",
          title: "Sponsor",
          render: (session) => {
            const sponsorInfo = sponsorsData?.data?.find(
              (sp) => sp.id === session.sponsorId
            );
            return (
              sponsorInfo && (
                <>
                  <Text size="sm">{sponsorInfo?.name}</Text>
                  <Text size="xs" opacity={0.5}>
                    {sponsorInfo?.subtitle}
                  </Text>
                </>
              )
            );
          },
        },
        {
          accessor: "speakers",
          render: (session) =>
            session?.speakers?.map((speaker, index) => {
              return (
                <Group key={`${speaker?.firstName}-${speaker?.lastName}`}>
                  <Text size="sm">
                    {speaker?.occupation
                      ? `${speaker?.firstName} ${speaker?.lastName}, ${speaker?.occupation}`
                      : `${speaker?.firstName} ${speaker?.lastName}`}
                  </Text>
                </Group>
              );
            }),
        },
        {
          accessor: "links",
          render: (session) =>
            session.links?.list?.map((link, index) => {
              const last = session.links?.list?.length - 1;
              return (
                <Group key={`${link?.title}`}>
                  <Text
                    size="sm"
                    style={{
                      textDecorationLine: "underline",
                    }}
                    component={Link}
                    to={
                      link?.url.startsWith("http://") ||
                      link?.url.startsWith("https://")
                        ? link?.url
                        : `//${link?.url}`
                    }
                    pb={index !== last ? 10 : 0}
                  >
                    {link.title}
                  </Text>
                </Group>
              );
            }),
        },
        {
          accessor: "tags",
          title: "Tags",
          render: (session) => {
            if (!session.tags) return;
            const tags = session.tags?.split(",");

            return tags.map((tag) => {
              const tagInfo = tagsData?.find((t) => t.id === Number(tag));
              const tagTitle = tagsData?.find(
                (t) => t.id === Number(tag)
              ).title;

              const tagColor = getTagColor(
                tagColorsData,
                defaultColors,
                tagInfo.subtype
              )?.color;

              return (
                <Badge
                  key={tag}
                  color={tagColor}
                  variant="light"
                  size="sm"
                  m={1}
                >
                  {tagTitle}
                </Badge>
              );
            });
          },
        },
        {
          accessor: "bookmarks",
          title: <IconBookmark />,
          textAlign: "center",
          render: (session) =>
            session.bookmarks !== 0 ? session.bookmarks : "",
        },
        {
          accessor: "actions",
          width: 100,
          render: (session) => (
            <Group justify="center">
              <Tooltip label="Edit">
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`${session.id}`}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete">
                <ActionIcon
                  size="md"
                  variant="light"
                  disabled={!enableActions}
                  color="red"
                  onClick={() => {
                    deleteModal(
                      session,
                      `delete '${session.title}' session`,
                      mutate,
                      isError,
                      error,
                      isSuccess
                    );
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      rowExpansion={{
        content: ({ record }) => (
          <ExpandedRow description={record.description} />
        ),
      }}
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
      sortStatus={sortStatus}
      onSortStatusChange={setSort}
    />
  );
}
