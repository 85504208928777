import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetAllSpeakers = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["speakers", params] },
    fetchOptions: { url: "/speakers", method: "GET", params },
  });

export const useGetSpeakerById = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["speakers", id] },
    fetchOptions: { url: `/speakers/${id}`, method: "GET" },
  });

export const useCreateSpeaker = () =>
  useApiMutation({
    fetchOptions: { url: "/speakers", method: "POST" },
    queryOptions: { queryKey: ["speakers"] },
    successMessage: { title: "Speaker successfully created" },
  });

export const usePatchSpeaker = (speakerId) =>
  useApiMutation({
    fetchOptions: { url: `/speakers/${speakerId}`, method: "PATCH" },
    queryOptions: { queryKey: ["speakers"] },
    successMessage: { title: "Speaker successfully updated" },
  });

export const useDeleteSpeaker = (id) =>
  useApiMutation({
    fetchOptions: { url: `/speakers/${id}`, method: "DELETE" },
    queryOptions: { queryKey: ["speakers"] },
    successMessage: { title: "Speaker successfully deleted" },
  });

export const useImportSpeakers = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["speakers"],
    },
    fetchOptions: {
      url: "/speakers/import",
      method: "POST",
    },
    successMessage: {
      title: "Speakers were imported successfully",
    },
  });
};
