import { ActionIcon } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useParams } from "react-router-dom";

import { useRemoveUserFromWorkspace } from "../../../api/workspace";

export default function RemoveUserModalButton({ userId }) {
  const { id } = useParams();

  // No idea on how to implement confirm message here.
  // I need ideas, please. Should we implement notifications?
  const { mutate } = useRemoveUserFromWorkspace({ workspaceId: id, userId });

  const openModal = () =>
    modals.openConfirmModal({
      title: "Remove user from workspace",
      children: "Are you sure you want to remove this user from workspace?",
      labels: { confirm: "Remove", cancel: "Cancel" },
      onConfirm: () => {
        mutate();
      },
    });

  return (
    <ActionIcon
      onClick={openModal}
      aria-label="Remove user from workspace"
      color="red"
      variant="light"
    >
      <IconTrash />
    </ActionIcon>
  );
}
