import { Text } from "@mantine/core";
import { useMatches } from "react-router-dom";

// This component renders the page title based on the current route
// And sets the name defined inside App.jsx router handle
export const PageTitle = () => {
  const matches = useMatches();

  const name = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.name))
    // then map them to crumb
    .map((match) => match.handle?.name)
    // Get the last one
    .pop();

  return (
    <Text size="md" style={{ fontWeight: 700 }}>
      {name ?? "Dashboard"}
    </Text>
  );
};
