import { useCallback, useState } from "react";
import { deepSanitize } from "@congrs/utils";

/**
 * Calls the API with the specified parameters.
 *
 * @param {Object} options - The options for the API call.
 * @param {string} options.url - The URL to make the API call to.
 * @param {string} options.method - The HTTP method to use for the API call.
 * @param {Object} options.body - The request body for the API call.
 * @param {string} options.token - The authorization token for the API call.
 * @returns {Promise<Object>} - A promise that resolves to the response data from the API.
 * @throws {Error} - If the API call fails, an error with the error message from the API response is thrown.
 */
export async function callApi({ url, method, body, token }) {
  if (!token && !url.includes("auth")) {
    throw new Error("No token provided");
  }
  const sanitizedBody = deepSanitize(body);
  const response = await fetch(`/api${url}`, {
    method,
    headers: {
      ...(method !== "DELETE" && { "Content-Type": "application/json" }),
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(sanitizedBody),
  });

  const data = await response.json();

  if (!response.ok) {
    //if response message is "Token expired", logout user
    if (data.error === "Token expired") {
      localStorage.removeItem("token");
      window.location.href = "/login";
      return;
    }

    const error = new Error(data.message);
    error.code = data.code;
    throw error;
  }

  return data;
}

// Kept because the app is literally not building without it
export function useApi() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const call = useCallback(async ({ url, method, body }) => {
    setIsLoading(true);
    setError(null);
    // Commented out for testing purposes Idea was when you change the page,
    // old data is still displayed
    // setData(null);

    const token = localStorage.getItem("token");

    try {
      const response = await callApi({ url, method, body, token });
      setData(response);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  });

  return { data, isLoading, error, call };
}
