import AppleSignin from "react-apple-signin-auth";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppleSignIn } from "../../api/auth";
import { AuthContext } from "../../services/context";
import { webConfig } from "../../../config";

const AppleSigninButton = ({ style }) => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const {
    mutate: appleSignIn,
    data,
    isSuccess,
    isError,
    error,
  } = useAppleSignIn();

  const handleAppleSignIn = async (response) => {
    appleSignIn(response);
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("token", data.token);
      setUser(data.user);
      navigate("/app");
    }

    if (isError) {
      navigate("/");
    }
  }, [isError, isSuccess, data, setUser, navigate, error]);

  return (
    <AppleSignin
      authOptions={{
        clientId: "rs.roundcube.congrs.service",
        scope: "email name",
        redirectURI: webConfig.baseUrl + "/callback",
        state: "",
        nonce: "nonce",
        usePopup: true,
      }}
      uiType="dark"
      className="apple-auth-btn"
      buttonExtraChildren="Sign in with AppleID"
      style={{
        ...style,
        fontSize: "12px",
        marginTop: "5px",
        borderRadius: "25px",
        height: "40px",
        align: "left",
      }}
      onSuccess={(response) => {
        handleAppleSignIn(response);
      }}
      onError={(error) => {
        console.log(error, "Apple Signin error");
        navigate("/");
      }}
    />
  );
};

export default AppleSigninButton;
