import { Breadcrumbs } from "@mantine/core";
import { useContext } from "react";
import { useGetEvents } from "../../api/event";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import EventCrumb from "./EventCrumb";
import WorkspaceCrumb from "./WorkspaceCrumb";

export default function Crumbs() {
  const { user } = useContext(AuthContext);
  const workspaces = user?.workspaces || [];
  const currentWorkspace = getCurrentWorkspace(user);

  const {
    data: eventsData,
    isPending: isPendingEvents,
    isSuccess,
    isError,
    error,
  } = useGetEvents({
    workspace: currentWorkspace?.id,
  });

  return (
    <Breadcrumbs
      styles={{
        separator: {
          fontSize: 14,
        },
      }}
    >
      <WorkspaceCrumb
        currentWorkspace={currentWorkspace}
        workspaces={workspaces}
        events={eventsData}
      />
      <EventCrumb
        currentWorkspace={currentWorkspace}
        workspaces={workspaces}
        events={eventsData}
        isPendingEvents={isPendingEvents}
      />
    </Breadcrumbs>
  );
}
