import { Flex, Title, Button, Paper, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { openEventDatesModal } from "./EventDatesModal";
import dayjs from "dayjs";

function EventDates({ event }) {
  return (
    <Flex direction="column">
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        mt="sm"
        mb="xs"
      >
        <Title order={2}>Dates</Title>
        <Button
          size="sm"
          variant="light"
          color="blue"
          onClick={() => {
            openEventDatesModal(event);
          }}
          leftSection={<IconEdit />}
        >
          Edit dates
        </Button>
      </Flex>
      <Paper withBorder p="sm" shadow="xs" radius="md">
        <Flex justify="space-between" mb="sm">
          <Text fw="bold" size="lg">
            Start date
          </Text>
          <Text fw="bold" size="lg">
            End date
          </Text>
        </Flex>
        <Flex justify="space-between">
          {event?.dates?.length > 0 ? (
            event.dates.map((date) => {
              return (
                <Text key={date.id}>
                  {dayjs(date.date).format("DD MMM YYYY")}
                </Text>
              );
            })
          ) : (
            <Text c="red">No dates.</Text>
          )}
        </Flex>
      </Paper>
    </Flex>
  );
}

export default EventDates;
