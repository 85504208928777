import { Menu, Text } from "@mantine/core";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import { useContext, useEffect } from "react";

import { useUpdateUser } from "../../api/user";
import { AuthContext } from "../../services/context";
import updateUserConfig from "../../services/userConfig";
import AddNewItem from "./AddNewItem";

export default function WorkspaceCrumb({
  currentWorkspace,
  workspaces,
  events,
}) {
  const { user, setUser } = useContext(AuthContext);

  const { mutate } = useUpdateUser({
    id: user.id,
    config: { hideNotification: true },
  });

  const defaultWorkspace = workspaces.find(
    (workspace) => workspace.id === currentWorkspace?.id
  );

  useEffect(() => {
    if (!currentWorkspace) {
      // If there's no current workspace, save the default one
      updateUserConfig({
        workspace: currentWorkspace,
        user,
        setUser,
        mutate,
      });
    }
  }, [currentWorkspace]);

  if (workspaces.length === 0) {
    return <Text c="red">No assigned workspace, please contact support.</Text>;
  }

  return (
    <WorkspaceMenu
      defaultWorkspace={defaultWorkspace}
      workspaces={workspaces}
      currentWorkspaceId={currentWorkspace?.id}
      onClick={(ws) =>
        updateUserConfig({
          workspace: ws,
          user,
          setUser,
          mutate,
        })
      }
    />
  );
}

const WorkspaceMenu = ({
  defaultWorkspace,
  workspaces,
  currentWorkspaceId,
  onClick,
}) => {
  return (
    <Menu withArrow>
      <Menu.Target>
        <div style={styles.breadcrumb}>
          {defaultWorkspace && <span>{defaultWorkspace.name}</span>}
          <IconChevronDown style={{ height: 12, width: 12, marginLeft: 5 }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Workspaces</Menu.Label>
        <Menu.Divider />
        {workspaces.map((ws) => (
          <Menu.Item
            key={ws.id}
            rightSection={
              ws.id === currentWorkspaceId ? <IconCheck size={14} /> : null
            }
            onClick={() => {
              if (ws.id !== currentWorkspaceId) {
                onClick(ws);
              }
            }}
          >
            {ws.name}
          </Menu.Item>
        ))}
        <Menu.Divider />
        <AddNewItem type="workspace" />
      </Menu.Dropdown>
    </Menu>
  );
};

const styles = {
  breadcrumb: {
    color: "grey",
    fontWeight: "bolder",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
