import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetCurrentUser } from "../api/user";
import LoadingPage from "../components/common/LoadingPage";
import { AuthContext } from "../services/context";

const Callback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setUser } = useContext(AuthContext);
  const { data, isSuccess, isError, error } = useGetCurrentUser();

  useEffect(() => {
    if (isError) {
      console.log("Get current user error", error);
      navigate("/");
    }

    if (isSuccess && data) {
      setUser(data);

      navigate(location.state?.returnTo || "/app");
    }
  }, [isError, isSuccess, data, error, location.state, setUser, navigate]);

  return <LoadingPage />;
};

export default Callback;
