import { z } from "zod";

export const optionsSchema = z.object({
  id: z.string(),
  title: z.string(),
});

export const choiceFollowUpValueSchema = z.object({
  id: z.string().min(1),
});

export const textFollowUpConditionSchema = z.object({
  text: z.string().min(1),
});

const basePollSchema = z.object({
  sessionId: z.string().min(1),
  workspaceId: z.number().min(1),
  eventId: z.number().min(1),
  title: z.string().min(3),
  description: z.string().optional(),
  followUpConditionType: z.enum(["isEqual", "isNotEqual"]).nullable(),
});

const textPollSchema = basePollSchema.extend({
  type: z.literal("text"),
  options: z.array().length(0),
  followUpConditionValue: z.string().optional().nullable(),
});

const choicePollSchema = basePollSchema.extend({
  type: z.enum(["singleChoice", "multipleChoice"]),
  options: z.array(optionsSchema),
  followUpConditionValue: z
    .array(choiceFollowUpValueSchema)
    .optional()
    .nullable(),
});

export const pollSchema = z.discriminatedUnion("type", [
  textPollSchema,
  choicePollSchema,
]);

const updateFields = {
  visibility: z.enum(["visible", "hidden"]),
  status: z.enum(["open", "closed"]),
  results: z.enum(["shared", "hidden"]),
};

export const createPollsSchema = z.object({
  polls: z.array(pollSchema),
  common: z.object(updateFields),
});

const updatedTextPollSchema = textPollSchema.extend(updateFields);
const updatedChoicePollSchema = choicePollSchema.extend(updateFields);

export const updatePollsSchema = z.discriminatedUnion("type", [
  updatedTextPollSchema,
  updatedChoicePollSchema,
]);
