import { modals } from "@mantine/modals";
import { useNotifyAnnouncement } from "../../api/announcements";
import { Flex, Text, Group, Button } from "@mantine/core";
import React from "react";

const SendAnnouncementNotificationsModal = ({ announcementId }) => {
  const { mutate, isPending } = useNotifyAnnouncement();

  return (
    <Flex direction="column">
      <Text size="sm">
        Do you want to send notifications for this announcement?
      </Text>
      <Group justify="flex-end" gap="sm" mt={16}>
        <Button
          onClick={() => {
            modals.closeAll();
          }}
          variant="default"
        >
          Cancel
        </Button>
        <Button
          loading={isPending}
          onClick={() => {
            mutate(
              { announcementId },
              {
                onSuccess: () => {
                  modals.closeAll();
                },
              }
            );
          }}
        >
          Send notifications
        </Button>
      </Group>
    </Flex>
  );
};

export const openSendAnnouncementNotificationsModal = (announcementId) => {
  modals.open({
    title: "Are you sure?",
    children: (
      <SendAnnouncementNotificationsModal announcementId={announcementId} />
    ),
  });
};

export default openSendAnnouncementNotificationsModal;
