import { ActionIcon, Group, Text, Badge, Flex } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useState } from "react";

import { DataTable } from "../DataTable";
import { EvaIcon } from "../EvaIcon";
import { useDeletePage } from "../../api/pages";
import deleteModal from "../deleteModal";

function PagesTable({ data, pagination }) {
  const [selectedPage, setSelectedPage] = useState(null);
  const { mutate, isError, isSuccess, error } = useDeletePage(selectedPage);

  const deletePageById = (page) => {
    setSelectedPage(page.id);
    deleteModal(
      page,
      `delete ${page.title}`,
      mutate,
      isError,
      error,
      isSuccess
    );
  };

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "icon",
          width: 75,
          textAlign: "center",
          render: (row) => (
            <Flex align="center" justify="center">
              <EvaIcon name={row.icon} styles={{ root: { width: 24 } }} />
            </Flex>
          ),
        },
        {
          accessor: "title",
          render: (row) => (
            <Group>
              <Text size="sm">{row.title}</Text>
              {row.isDraft && (
                <Badge variant="dot" color="grey">
                  DRAFT
                </Badge>
              )}
            </Group>
          ),
        },
        {
          accessor: "actions",
          width: 100,
          render: (row) => (
            <Group justify="right">
              <ActionIcon
                size="md"
                variant="light"
                component={Link}
                to={`${row.id}`}
              >
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                size="md"
                variant="light"
                color="red"
                onClick={() => deletePageById(row)}
              >
                <IconTrash />
              </ActionIcon>
            </Group>
          ),
        },
      ]}
      page={pagination.data.page}
      onPageChange={(page) =>
        pagination.setPagination({ ...pagination.data, page })
      }
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
    />
  );
}

export default PagesTable;
