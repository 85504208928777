import React from "react";
import { useForm } from "react-hook-form";
import { SegmentedControl, Button, Group } from "@mantine/core";
import { TextInput, Textarea } from "react-hook-form-mantine";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { fullFormats } from "ajv-formats/dist/formats";
import { useSendEmailToAttendees } from "../../api/attendees";

const schema = {
  type: "object",
  properties: {
    subject: { type: "string", minLength: 1 },
    body: { type: "string", minLength: 1 },
    emailType: { type: "string", enum: ["ticketDownload", "regular"] },
  },
  required: ["subject", "body", "emailType"],
};

const SendEmail = ({
  attendeeIds,
  eventId,
  onSuccess,
  onClose,
  numberOfRecipents,
  template,
}) => {
  const defaultValues = {
    subject: "",
    body: "",
    emailType: "ticketDownload",
  };

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues,
    resolver: ajvResolver(schema, { formats: fullFormats }),
  });

  const { mutate: sendEmailToAttendees, isPending: isSendingEmail } =
    useSendEmailToAttendees();

  const emailType = watch("emailType");

  const onSubmit = (data) => {
    const formattedBody = data.body.replace(/\n/g, "<br />");

    try {
      sendEmailToAttendees({
        attendeeIds,
        eventId,
        email: {
          subject: data.subject,
          body: formattedBody,
          type: data.emailType,
        },
      });
      onSuccess();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SegmentedControl
        color="blue"
        fullWidth
        value={emailType}
        onChange={(value) => setValue("emailType", value)}
        data={[
          { label: "Ticket Download", value: "ticketDownload" },
          { label: "Regular", value: "regular" },
        ]}
        style={{ marginBottom: 10 }}
      />

      <div
        style={{
          backgroundColor: "#dde5f0",
          padding: "10px 20px",
          margin: "10px 0",
          fontSize: 14,
        }}
      >
        Hint: You can use {`{{ firstName }}`} and {`{{ lastName }}`} in the
        subject and body of the email.
      </div>
      {numberOfRecipents > 1 && (
        <>
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            Recipients
          </p>
          <p
            style={{
              fontSize: 14,
              margin: 0,
            }}
          >
            {numberOfRecipents} selected attendees
          </p>
        </>
      )}
      <TextInput
        mt="md"
        name="subject"
        control={control}
        placeholder="Enter email subject"
        label="Subject"
      />
      <Textarea
        mt="md"
        name="body"
        control={control}
        placeholder="Enter email body"
        label="Body"
        rows={5}
      />
      {emailType === "ticketDownload" && (
        <div
          dangerouslySetInnerHTML={{
            __html: template.replace(/<img[^>]*>/g, ""),
          }}
        />
      )}
      <Group
        style={{ float: "right", paddingBottom: 40 }}
        position="right"
        mt="md"
      >
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSendingEmail} type="submit" color="blue">
          Send
        </Button>
      </Group>
    </form>
  );
};

export default SendEmail;
