import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Container, Paper, Text, Stack } from "@mantine/core";
import { useContext, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import React from "react";
import {
  Checkbox,
  Select,
  TextInput,
  Textarea,
  Radio,
} from "react-hook-form-mantine";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useGetPoll, useUpdatePoll } from "../../api/polls";
import { useGetSessions } from "../../api/session";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { omit } from "lodash";
import { updatePollsSchema } from "./schema";

export default function EditPoll() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);

  const { id } = useParams();
  const { data, isPending } = useGetPoll(id);

  const { data: sessionsData } = useGetSessions({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });

  const { mutate } = useUpdatePoll({
    id,
    ...data,
  });

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      title: data?.title,
      description: data?.description,
      visibility: data?.isVisible ? "visible" : "hidden",
      status: data?.status,
      results: data?.results ? "shared" : "hidden",
      options: data?.options,
      type: data?.type,
      followUpConditionValueForChoice:
        data?.followUpConditionValueForChoice || [],
      followUpConditionValueForText: data?.followUpConditionValueForText || "",
      followUpConditionType: data?.followUpConditionType || "isEqual",
      followUpPollId: data?.followUpPollId,
      followUpQuestion: !!data?.followUpPollId,
      workspaceId: currentWorkspace.id,
      eventId: currentWorkspace.eventId,
    },
    resolver: zodResolver(updatePollsSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  useEffect(() => {
    if (data) {
      reset({
        title: data?.title,
        description: data?.description,
        visibility: data?.isVisible ? "visible" : "hidden",
        status: data?.status,
        results: data?.areResultsVisible ? "shared" : "hidden",
        options: data?.options,
        type: data?.type,
        followUpConditionValueForChoice:
          data.type === "singleChoice" || data.type === "multipleChoice"
            ? data?.followUpConditionValue
            : [],
        followUpConditionValueForText:
          data.type === "text" ? data.followUpConditionValue[0].text : "",
        followUpConditionType: data?.followUpConditionType,
        followUpPollId: data?.followUpPollId,
        followUpQuestion: !!data?.followUpPollId,
        workspaceId: currentWorkspace.id,
        eventId: currentWorkspace.eventId,
      });
    }
  }, [data, reset]);

  const pollType = watch("type");
  const followUpQuestion = watch("followUpQuestion");
  const followUpPollId = watch("followUpPollId");
  const followUpConditionValueForChoice = watch(
    "followUpConditionValueForChoice"
  );
  const followUpConditionValueForText = watch("followUpConditionValueForText");
  const followUpConditionType = watch("followUpConditionType");
  const options = watch("options");

  useEffect(() => {
    if (followUpQuestion && followUpConditionType == null) {
      setValue("followUpConditionType", "isEqual");
    }
  }, [followUpQuestion, setValue]);

  const [prevType, setPrevType] = React.useState(null);

  useEffect(() => {
    const subscription = watch((value) => {
      const currentType = value.type;

      if (currentType !== prevType) {
        setPrevType(currentType);

        if (currentType === "text") {
          // Remove all entries if type is text
          remove();
        } else if (
          currentType !== "text" &&
          (!value.options || value.options.length === 0)
        ) {
          // Add a new entry if options are empty
          append({ id: uuidv4(), title: "" }, { id: uuidv4(), title: "" });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, append, remove, prevType]);

  const onSubmit = (data) => {
    const followUpConditionValue = followUpQuestion
      ? data.type === "text"
        ? [{ text: followUpConditionValueForText }]
        : followUpConditionValueForChoice
      : null;

    const submissionData = {
      ...data,
      options: data.type === "text" ? null : data.options,
      followUpConditionValue,
      followUpConditionType: followUpQuestion
        ? data.followUpConditionType
        : null,
      followUpPollId: followUpQuestion ? followUpPollId : null,
      isVisible: data.visibility === "visible",
      areResultsVisible: data.results === "shared",
    };

    mutate(omit(submissionData, ["results", "visibility"]));
  };

  if (isPending) return <Text>Loading...</Text>;

  const handleCheckboxChange = (e, value) => {
    const isChecked = e.target.checked;

    let updatedFollowUpConditionValue;

    if (pollType === "singleChoice") {
      updatedFollowUpConditionValue = isChecked ? [{ id: value }] : [];
    } else {
      updatedFollowUpConditionValue = isChecked
        ? [...followUpConditionValueForChoice, { id: value }]
        : followUpConditionValueForChoice.filter((item) => item.id !== value);
    }

    setValue("followUpConditionValueForChoice", updatedFollowUpConditionValue);
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            control={control}
            label="Session"
            placeholder="Select session"
            name="sessionId"
            data={sessionsData?.map((s) => ({
              value: `${s.id}`,
              label: s.title,
            }))}
            defaultValue={`${data?.sessionId}`}
            allowDeselect={false}
          />
          <TextInput
            label="Question"
            placeholder="Edit question"
            type="text"
            name="title"
            withAsterisk
            defaultValue={data?.title}
            mt="md"
            control={control}
          />
          <Textarea
            label="Description"
            placeholder="Edit description"
            type="text"
            name="description"
            defaultValue={data?.description}
            control={control}
            mt="md"
          />
          <div style={{ marginTop: 20 }}>
            <label style={styles.label}>Question Type</label>
            <Radio.Group control={control} name="type">
              <Stack>
                <Radio
                  control={control}
                  value="singleChoice"
                  name="type"
                  label="Single choice question"
                />
                <Radio
                  control={control}
                  value="multipleChoice"
                  name="type"
                  label="Multiple choice question"
                />
                <Radio
                  control={control}
                  value="text"
                  name="type"
                  label="Text question"
                />
              </Stack>
            </Radio.Group>
          </div>
          {watch("type") !== "text" && (
            <div style={{ marginTop: 20 }}>
              <label style={styles.label}>Options</label>
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  style={{ display: "flex", marginBottom: 20 }}
                >
                  <TextInput
                    name={`options.${index}.id`}
                    value={field.id}
                    control={control}
                    type="hidden"
                    defaultValue={field.id}
                  />
                  <TextInput
                    placeholder="Option"
                    type="text"
                    name={`options.${index}.title`}
                    control={control}
                    defaultValue={field.title}
                  />
                  <Button
                    color="gray"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    type="button"
                    onClick={() => append({ id: uuidv4(), title: "" })}
                  >
                    +
                  </Button>
                  <Button
                    color="gray"
                    type="button"
                    onClick={() => remove(index)}
                    disabled={fields.length === 2}
                  >
                    -
                  </Button>
                </div>
              ))}
            </div>
          )}
          <div style={styles.wrapper}>
            <Select
              control={control}
              label="Visibility"
              name="visibility"
              data={[
                { value: "visible", label: "Visible" },
                { value: "hidden", label: "Hidden" },
              ]}
              allowDeselect={false}
            />
            <Select
              control={control}
              label="Status"
              name="status"
              data={[
                {
                  value: "open",
                  label: "Open",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
              ]}
              allowDeselect={false}
            />
            <Select
              control={control}
              label="Results"
              name="results"
              data={[
                { value: "shared", label: "Shared" },
                { value: "hidden", label: "Hidden" },
              ]}
              description="Are the results of this poll shared with the users after they submit their vote?"
              allowDeselect={false}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Checkbox
              label="Follow Up Question"
              name={`followUpQuestion`}
              mt="md"
              control={control}
            />
            {watch("followUpQuestion") && (
              <>
                <div style={{ marginTop: 20 }}>
                  <label style={styles.label}>
                    Follow Up Question Condition
                  </label>
                </div>
                <Radio.Group control={control} name={`followUpConditionType`}>
                  <Stack>
                    <Radio
                      control={control}
                      value="isEqual"
                      name={`followUpConditionType`}
                      label="is Equal"
                    />
                    <Radio
                      control={control}
                      value="isNotEqual"
                      name={`followUpConditionType`}
                      label="is Not Equal"
                    />
                  </Stack>
                </Radio.Group>
                {pollType === "text" ? (
                  <TextInput
                    label="Condition Value"
                    placeholder="Enter text value"
                    type="text"
                    name="followUpConditionValueForText"
                    control={control}
                    mt="md"
                  />
                ) : (
                  <Stack>
                    <div style={{ marginTop: 10, marginBottom: -10 }}>
                      <label style={styles.label}>
                        Follow Up Question Conditions Value(s)
                      </label>
                    </div>
                    {options.map((option) => (
                      <Checkbox
                        key={option.id}
                        label={option.title}
                        control={control}
                        name="followUpConditionValueForChoice"
                        checked={followUpConditionValueForChoice
                          ?.map((i) => i.id)
                          ?.includes(option.id)}
                        onChange={(e) => handleCheckboxChange(e, option.id)}
                        value={option.id}
                      />
                    ))}
                  </Stack>
                )}
                <div style={{ width: 150, marginTop: 10 }}>
                  <TextInput
                    label="Follow Up Poll Id"
                    placeholder="Enter poll id"
                    type="text"
                    name="followUpPollId"
                    control={control}
                  />
                </div>
              </>
            )}
          </div>
          <Button type="submit" mt="md">
            Update Poll
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

const styles = {
  label: {
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  },
  wrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
};
