import {
  Container,
  Paper,
  Text,
  Title,
  Button,
  Avatar,
  Group,
  FileButton,
} from "@mantine/core";
import CropImage from "../Events/Customize/CropImage";
import { modals } from "@mantine/modals";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Textarea, TextInput } from "react-hook-form-mantine";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreateSpeaker } from "../../api/speakers";
import { toBase64 } from "../../services/helper";

function Create() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const [currentProfile, setCurrentProfile] = useState(null);

  const currentProfileUrl = currentProfile
    ? URL.createObjectURL(currentProfile)
    : null;

  const defaultValues = {
    firstName: "",
    lastName: "",
    occupation: "",
    biography: "",
    eventId,
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (currentProfile) {
      toBase64(currentProfile).then((base64) => {
        setValue("picture", base64);
      });
    } else {
      setValue("picture", "");
    }
  }, [currentProfile, setValue]);

  const { mutate, isPending } = useCreateSpeaker();

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        reset(defaultValues);
      },
    });
  };

  const openCropModal = (file) => {
    const imageSrc = URL.createObjectURL(file);

    modals.open({
      title: "Crop image",
      children: (
        <CropImage
          imageSrc={imageSrc}
          imageName={file.name}
          setNewHeaderImage={setCurrentProfile}
          aspectRatio={1 / 1}
        />
      ),
    });

    // Clean up the URL object after usage
    return () => URL.revokeObjectURL(imageSrc);
  };

  return (
    <Container w={600}>
      <Title order={1}>Create a new speaker</Title>
      <Text c="dimmed">
        Fill out the form below to create a new speaker. Speakers are a
        placeholder data for now. When you create a speaker, you can assign them
        to a session. When you assign an userId to a speaker, the speaker will
        be clickable and will redirect to the user profile.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="sm" px="sm" py="md" style={{ marginTop: 20 }}>
          <Text size="sm" fw={600}>
            Profile picture
          </Text>
          <Text size="xs" c="dimmed" style={{ marginBottom: 10 }}>
            Upload a profile picture for the speaker. The picture should be
            square and it should not be larger than 5MB.
          </Text>
          <Group>
            <Avatar
              src={currentProfileUrl}
              alt="avatar"
              radius="xl"
              size="xl"
              style={{ marginBottom: 20 }}
            />

            <FileButton onChange={openCropModal} accept="image/png,image/jpeg">
              {(props) => (
                <Button size="compact-md" variant="light" {...props}>
                  Upload picture
                </Button>
              )}
            </FileButton>
          </Group>

          <TextInput
            label="First Name"
            placeholder="John"
            name="firstName"
            control={control}
            required
          />
          <TextInput
            label="Last Name"
            placeholder="Doe"
            name="lastName"
            control={control}
            required
            mt="sm"
          />
          <TextInput
            label="Occupation"
            placeholder="Software Engineer at RoundCube"
            name="occupation"
            control={control}
            mt="sm"
          />
          <Textarea
            label="Biography"
            placeholder="Tell us about the speaker"
            name="biography"
            control={control}
            rows={3}
            mt="sm"
          />

          <Button
            type="submit"
            color="blue"
            style={{ marginTop: 20 }}
            loading={isPending}
          >
            Create speaker
          </Button>
        </Paper>
      </form>
    </Container>
  );
}

export default Create;
