import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";

import { useEffect } from "react";
import { useDeleteUser, useGetCurrentUser } from "../../api/user";

export default function DeleteAccountButton() {
  const { data: user } = useGetCurrentUser();

  const { mutate, isSuccess, isError, error } = useDeleteUser(user?.id);

  const openModal = () =>
    modals.openConfirmModal({
      title: "Delete user",
      children:
        "Are you sure you want to delete account? This action cannot be undone.",
      labels: { confirm: "Remove", cancel: "Cancel" },
      onConfirm: () => {
        mutate();
      },
    });

  useEffect(() => {
    if (isSuccess) {
      notifications.show({
        title: "User deleted",
        message: "Account was deleted successfully",
        color: "green",
      });
    }

    if (isError) {
      notifications.show({
        title: "Error",
        message: `Error while deleting your account: ${error.message}`,
        color: "red",
      });
    }
  });

  return (
    <Button onClick={openModal} color="red" variant="outline">
      Delete account
    </Button>
  );
}
