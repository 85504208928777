import { Container, Title, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";
import {
  useGetAllAnnouncements,
  useGetAnnouncement,
} from "../../api/announcements";
import { useGetTags } from "../../api/tag";
import EditAnnouncementForm from "./EditAnnouncementForm";
import EditSpecialInvite from "../SpecialInvite/Edit";

const EditAnnouncement = () => {
  const { announcementId } = useParams();

  const { data, isPending, isSuccess } = useGetAnnouncement(announcementId);

  const { data: allAnnouncements } = useGetAllAnnouncements({
    eventId: data?.eventId,
    page: 1,
    limit: 20,
    getAll: true,
  });

  const { data: tags, isSuccess: tagsSuccess } = useGetTags({
    eventId: data?.eventId,
    type: "people",
  });

  const moreThanOneVisible =
    allAnnouncements?.data.filter((announcement) => announcement.isVisible)
      .length >= 1;

  if (isSuccess && data?.type === "special") {
    return <EditSpecialInvite data={data} tags={tags} />;
  }

  return (
    <Container size={500}>
      <Title order={1}>Edit Announcement</Title>
      {isPending && <Loader />}
      {isSuccess && tagsSuccess && (
        <EditAnnouncementForm
          data={data}
          moreThanOneVisible={moreThanOneVisible}
          tags={tags}
        />
      )}
    </Container>
  );
};

export default EditAnnouncement;
