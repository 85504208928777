import { Group, Tooltip, ActionIcon } from "@mantine/core";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

export const EditActions = ({ isExisting, onSave, onCancel }) => (
  <Group justify="right" wrap="nowrap">
    {!isExisting ? (
      <>
        <Tooltip label="Save">
          <ActionIcon size="md" variant="light" color="green" onClick={onSave}>
            <IconCircleCheck />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Cancel">
          <ActionIcon size="md" variant="light" color="red" onClick={onCancel}>
            <IconCircleX />
          </ActionIcon>
        </Tooltip>
      </>
    ) : null}
  </Group>
);
