import {
  Container,
  Paper,
  Text,
  Title,
  Button,
  Group,
  Avatar,
  FileButton,
} from "@mantine/core";
import CropImage from "../Events/Customize/CropImage";
import { modals } from "@mantine/modals";
import { useForm } from "react-hook-form";
import { Textarea, TextInput } from "react-hook-form-mantine";
import { useState, useEffect } from "react";
import { toBase64 } from "../../services/helper";
import { useGetSpeakerById, usePatchSpeaker } from "../../api/speakers";
import { useParams } from "react-router-dom";
import { extractDirtyData } from "../../utils/dirtyData";

function EditSpeakerForm({ speaker }) {
  const { firstName, lastName, occupation, biography, id, picture } = speaker;

  const [currentProfile, setCurrentProfile] = useState(null);

  const currentProfileUrl = currentProfile
    ? URL.createObjectURL(currentProfile)
    : picture;

  const defaultValues = {
    firstName,
    lastName,
    occupation,
    biography,
    picture: picture ?? "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
    setValue,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (currentProfile) {
      toBase64(currentProfile).then((base64) => {
        setValue("picture", base64, { shouldDirty: true });
      });
    } else {
      setValue("picture", "");
    }
  }, [currentProfile, setValue]);

  const { mutate, isPending } = usePatchSpeaker(id);

  const onSubmit = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    mutate(dirtyData, {
      onSuccess: () => {
        reset({ ...defaultValues, ...dirtyData });
      },
    });
  };

  const openCropModal = (file) => {
    const imageSrc = URL.createObjectURL(file);

    modals.open({
      title: "Crop image",
      children: (
        <CropImage
          imageSrc={imageSrc}
          imageName={file.name}
          setNewHeaderImage={setCurrentProfile}
          aspectRatio={1 / 1}
        />
      ),
    });

    // Clean up the URL object after usage
    return () => URL.revokeObjectURL(imageSrc);
  };

  return (
    <Container w={600}>
      <Title order={1}>Edit speaker</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="sm" px="sm" py="md" style={{ marginTop: 20 }}>
          <Text size="sm" fw={600}>
            Profile picture
          </Text>
          <Text size="xs" c="dimmed" style={{ marginBottom: 10 }}>
            Upload a profile picture for the speaker. The picture should be
            square and it should not be larger than 5MB.
          </Text>
          <Group>
            <Avatar
              src={currentProfileUrl}
              alt="avatar"
              radius="xl"
              size="xl"
              style={{ marginBottom: 20 }}
              name={`${firstName} ${lastName}`}
              color="initials"
            />

            <FileButton onChange={openCropModal} accept="image/png,image/jpeg">
              {(props) => (
                <Button size="compact-md" variant="light" {...props}>
                  Upload image
                </Button>
              )}
            </FileButton>
          </Group>
          <TextInput
            label="First Name"
            placeholder="John"
            name="firstName"
            control={control}
            required
          />
          <TextInput
            label="Last Name"
            placeholder="Doe"
            name="lastName"
            control={control}
            required
            mt="sm"
          />
          <TextInput
            label="Occupation"
            placeholder="Software Engineer at RoundCube"
            name="occupation"
            control={control}
            mt="sm"
          />
          <Textarea
            label="Biography"
            placeholder="Tell us about the speaker"
            name="biography"
            control={control}
            rows={3}
            mt="sm"
          />

          <Button
            type="submit"
            color="blue"
            style={{ marginTop: 20 }}
            loading={isPending}
            disabled={!isDirty}
          >
            Edit speaker
          </Button>
        </Paper>
      </form>
    </Container>
  );
}

const EditSpeaker = () => {
  const { speakerId } = useParams();
  const {
    data: speaker,
    isPending: speakerLoading,
    isSuccess,
  } = useGetSpeakerById(speakerId);

  if (speakerLoading) {
    return <Text>Loading...</Text>;
  }

  if (isSuccess) {
    return <EditSpeakerForm speaker={speaker} />;
  }
};

export default EditSpeaker;
