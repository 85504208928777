const defaultColors = [
  {
    subtype: "categoryOfUser",
    color: "#ffd05c",
  },
  {
    subtype: "topicOfInterest",
    color: "#2986cc",
  },
  {
    subtype: "dressCode",
    color: "#FF69B4",
  },
  {
    subtype: "sessionType",
    color: "#5baa5b",
  },
  {
    subtype: "topic",
    color: "#FF0000",
  },
];

export default defaultColors;
