import { useContext } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useState } from "react";

import { useGetTags } from "../api/tag";
import TagsTable from "../components/Tags/TagsTable";

function Announcements() {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState({
    columnAccessor: "title",
    direction: "asc",
  });

  const [typeFilter, setTypeFilter] = useState(null);
  const [subtypeFilter, setSubtypeFilter] = useState(null);

  const currentWorkspace = getCurrentWorkspace(user);
  const eventId = currentWorkspace.eventId;

  const { data, isPending } = useGetTags({
    eventId,
    page,
    sort,
    type: typeFilter,
    subtype: subtypeFilter,
  });

  return (
    <TagsTable
      data={data}
      isLoading={isPending}
      role={user.role}
      page={page}
      setPage={setPage}
      sortStatus={sort}
      setSort={setSort}
      typeFilter={typeFilter}
      setTypeFilter={setTypeFilter}
      subtypeFilter={subtypeFilter}
      setSubtypeFilter={setSubtypeFilter}
    />
  );
}

export default Announcements;
