import { Menu } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export default function AddNewItem({ type }) {
  return (
    <Menu.Item
      component={Link}
      to={`/app/${type}s/create`}
      leftSection={<IconPlus size={20} />}
    >
      Create new {type}
    </Menu.Item>
  );
}
