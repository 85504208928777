import { ActionIcon, Badge, Group, Select, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useMemo } from "react";

import { DataTable } from "../DataTable";
import defaultColors from "./utils/defaultColors";
import deleteModal from "../deleteModal";
import { useDeleteTag } from "../../api/tag";
import subtypeOptions from "./utils/subtypeOptions";
import { addSpacesToCamelCase } from "./utils/addSpacesToCamelCase";

export default function TagsTable({
  data,
  isLoading,
  role,
  page,
  setPage,
  sortStatus,
  setSort,
  typeFilter,
  setTypeFilter,
  subtypeFilter,
  setSubtypeFilter,
}) {
  const enableActions = role === "sysAdmin" || role === "wsAdmin";
  const { mutate, isError, error, isSuccess } = useDeleteTag();

  const subtypes = useMemo(() => subtypeOptions(typeFilter), [typeFilter]);
  const allSubtypes = subtypeOptions();

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "title",
          sortable: true,
        },
        {
          accessor: "type",
          filter: (
            <Select
              label="Filter by type"
              placeholder="All types"
              value={typeFilter}
              onChange={(value) => {
                setTypeFilter(value);
                // Clear subtype filter when type changes
                setSubtypeFilter("");
              }}
              data={[
                { value: "people", label: "People" },
                { value: "session", label: "Session" },
              ]}
              clearable
            />
          ),
          render: (tag) => <Badge variant="default">{tag.type}</Badge>,
        },
        {
          accessor: "subtype",
          filter: (
            <Select
              label="Filter by subtype"
              placeholder="All subtypes"
              value={subtypeFilter}
              onChange={(value) => setSubtypeFilter(value)}
              data={typeFilter ? subtypes : allSubtypes}
              clearable
            />
          ),
          render: (tag) => (
            <Badge
              variant="light"
              color={
                tag.color ||
                defaultColors.find((item) => item.subtype === tag.subtype).color
              }
            >
              {addSpacesToCamelCase(tag.subtype)}
            </Badge>
          ),
        },
        {
          accessor: "usageCount",
          title: "Times used",
          sortable: true,
        },
        {
          accessor: "actions",
          width: 100,
          render: (tag) => (
            <Group justify="center">
              <Tooltip label="Edit">
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`${tag.id}`}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete">
                <ActionIcon
                  size="md"
                  variant="light"
                  disabled={!enableActions}
                  color="red"
                  onClick={() =>
                    deleteModal(
                      tag,
                      `delete '${tag.title}'`,
                      mutate,
                      isError,
                      error,
                      isSuccess
                    )
                  }
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
      sortStatus={sortStatus}
      onSortStatusChange={setSort}
    />
  );
}
