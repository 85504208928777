import { DataTable as MantineDataTable } from "mantine-datatable";

/**
 * Renders a data table with default styles and settings.
 *
 * @param {import("mantine-datatable").DataTableProps} props - Additional props passed to the MantineDataTable component.
 * @returns {JSX.Element} A styled data table component.
 */
export function DataTable({ ...props }) {
  return (
    <MantineDataTable
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      minHeight={180}
      noRecordsText="No results found"
      {...props}
    />
  );
}
