import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Title } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useAddUserToWorkspace } from "../../../api/workspace";
import SearchUsers from "./SearchUsers";
import { WorkspaceMembersTable } from "./WorkspaceMembersTable";

export default function AddUserToWorkspace({ isSysAdmin }) {
  const { id } = useParams();

  const { mutate, isPending: isLoading } = useAddUserToWorkspace({
    workspaceId: id,
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: ajvResolver({
      type: "object",
      properties: {
        userId: { type: "string", minLength: 1 },
      },
      required: ["userId"],
    }),
  });

  return (
    <Container size={700} mt={16}>
      <Title order={2}>Users in workspace</Title>
      <Paper withBorder shadow="md" p={16} radius="md">
        {isSysAdmin && (
          <form onSubmit={handleSubmit(mutate)}>
            <SearchUsers
              label="Add users to workspace"
              placeholder="Search users..."
              name="userId"
              control={control}
              mt="md"
              disabled={isLoading}
            />

            <Button
              type="submit"
              mt="md"
              loading={isLoading}
              disabled={!isDirty || isLoading}
            >
              Add user
            </Button>
          </form>
        )}
        <WorkspaceMembersTable />
      </Paper>
    </Container>
  );
}
