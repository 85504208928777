import {
  Button,
  Container,
  Paper,
  Title,
  Badge,
  ActionIcon,
  Divider,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useContext } from "react";

import { openColorModal } from "./ColorModal";
import { useGetTagColors, useResetTagColor } from "../../api/tag";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import defaultColors from "./utils/defaultColors";
import { addSpacesToCamelCase } from "./utils/addSpacesToCamelCase";

function ColorTags() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;
  const { data } = useGetTagColors(currentEventId);
  const { mutate: reset, isPending: resetPending } = useResetTagColor();

  function isDataDifferentFromDefaults(data, defaultColors) {
    if (!Array.isArray(data)) return false;

    const sortAndStringify = (arr) =>
      JSON.stringify(
        arr.slice().sort((a, b) => a.subtype.localeCompare(b.subtype))
      );

    return sortAndStringify(data) !== sortAndStringify(defaultColors);
  }

  const isDifferent = isDataDifferentFromDefaults(data, defaultColors);

  const tagColors = data && data.length !== 0 ? data : defaultColors;

  const handleReset = () => {
    reset(currentEventId);
  };

  return (
    <Container size={500}>
      <Title>Color tags</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        {tagColors.map((item) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            key={item.subtype}
          >
            <Badge color={item.color} variant="light">
              {addSpacesToCamelCase(item.subtype)}
            </Badge>
            <Divider
              orientation="horizontal"
              style={{
                flex: 1,
                opacity: 0.1,
                borderStyle: "dashed",
                borderWidth: 0.1,
              }}
            />
            <ActionIcon
              variant="outline"
              onClick={() => openColorModal({ item, currentEventId })}
            >
              <IconEdit />
            </ActionIcon>
          </div>
        ))}

        <Button
          onClick={handleReset}
          loading={resetPending}
          disabled={!isDifferent}
          mt={16}
        >
          Reset to default colors
        </Button>
      </Paper>
    </Container>
  );
}

export default ColorTags;
