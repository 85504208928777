import { Container, Loader, Title } from "@mantine/core";
import { useParams } from "react-router-dom";
import EditPageForm from "./Form";
import { useGetPage } from "../../../api/pages";

const EditPage = () => {
  const { id } = useParams();

  const { data, isPending, isSuccess } = useGetPage(id);

  return (
    <Container>
      <Title order={1}>Edit Page</Title>
      {isPending && <Loader />}
      {isSuccess && <EditPageForm data={data} />}
    </Container>
  );
};

export default EditPage;
