import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Anchor,
  Button,
  Container,
  Fieldset,
  MultiSelect,
  Paper,
  Select,
  Text,
  TextInput as TextInputMantine,
} from "@mantine/core";
import "@mantine/dates/styles.css";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  TextInput,
  DateTimePicker,
  Textarea,
  Select as FormSelect,
} from "react-hook-form-mantine";
import { fullFormats } from "ajv-formats/dist/formats";
import { Link } from "react-router-dom";

import { useCreateSession } from "../../api/session";
import { useGetSponsors } from "../../api/sponsors";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { formatDateTime } from "./utils/formatDateTime";
import { useGetSessionTagsByEventId } from "../../api/tag";
import { formatTagLabel } from "./utils/formatTagLabel";
import { useGetAllSpeakers } from "../../api/speakers";
import { useGetEvent, useGetEventLocations } from "../../api/event";

export default function CreateSession() {
  const { user } = useContext(AuthContext);

  const currentWorkspace = getCurrentWorkspace(user);

  const { data: event } = useGetEvent(currentWorkspace.eventId);

  const { data: speakers } = useGetAllSpeakers({
    eventId: currentWorkspace.eventId,
    limit: 250,
  });

  const speakersOptions = speakers?.data?.map((speaker) => ({
    label: `${speaker.firstName} ${speaker.lastName}, ${speaker.occupation}`,
    value: speaker.id.toString(),
  }));

  const {
    data: sponsors,
    isSuccess: sponsorsSuccess,
    isError: sponsorsIsError,
    error: sponsorsError,
  } = useGetSponsors({
    eventId: currentWorkspace.eventId,
  });

  const { data: locations } = useGetEventLocations(currentWorkspace.eventId);

  const locationsOptions = locations?.map((location) => ({
    label: location.name,
    value: location.id.toString(),
  }));

  const { mutate, isPending, isSuccess } = useCreateSession();

  let sponsorsOptions;
  if (sponsorsSuccess) {
    sponsorsOptions = sponsors.data.map((s) => ({
      label: s.name,
      value: s.id.toString(),
    }));
  }

  const { data: sessionTags, isSuccess: tagsSuccess } =
    useGetSessionTagsByEventId(currentWorkspace.eventId);

  const [tagOptions, setTagOptions] = useState({
    dressCode: [],
    sessionType: [],
    topic: [],
  });

  useEffect(() => {
    if (tagsSuccess) {
      const getTagOptions = (subtype) =>
        sessionTags
          .filter((tag) => tag.subtype === subtype)
          .map((tag) => ({ label: tag.title, value: tag.id.toString() }));

      setTagOptions({
        dressCode: getTagOptions("dressCode"),
        sessionType: getTagOptions("sessionType"),
        topic: getTagOptions("topic"),
      });
    }
  }, [tagsSuccess, sessionTags]);

  const enableActions = user?.role === "sysAdmin" || user?.role === "wsAdmin";

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      locationId: "",
      starting: "",
      ending: "",
      speakers: [],
      sponsorId: "",
      links: "",
      tags: {
        dressCode: [],
        sessionType: [],
        topic: [],
      },
    },
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          title: { type: "string", minLength: 1 },
          locationId: { type: "number", minimum: 1 },
          starting: {
            type: ["string", "object"],
            format: "date-time",
            errorMessage: "Starting date is required",
          },
          ending: {
            type: ["string", "object"],
            format: "date-time",
            errorMessage: "Ending date is required",
          },
          tags: {
            type: "object",
            properties: {
              dressCode: {
                type: "array",
                items: { type: "string" },
              },
              sessionType: {
                type: "array",
                items: { type: "string" },
              },
              topic: {
                type: "array",
                items: { type: "string" },
              },
            },
          },
        },
        required: ["title", "locationId", "starting", "ending"],
      },
      { formats: fullFormats, allowUnionTypes: true, coerceTypes: true }
    ),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const onSubmit = (data) => {
    data.starting = formatDateTime(start.toString());
    data.ending = formatDateTime(end.toString());
    data.wsId = currentWorkspace.id;
    data.eventId = currentWorkspace.eventId;
    data.sponsorId = data.sponsorId ? Number(data.sponsorId) : null;
    data.speakers = data.speakers.map((speaker) => Number(speaker));
    data.links = { list: data.links };

    data.tags = ["dressCode", "sessionType", "topic"]
      .reduce((acc, type) => acc.concat(data.tags[type] || []), [])
      .map((tag) => Number(tag));

    mutate(data);
  };

  if (sponsorsIsError) {
    return <Text>Error while getting sponsors data: {sponsorsError}</Text>;
  }

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            placeholder="Title"
            type="text"
            name="title"
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />
          <Textarea
            label="Description"
            name="description"
            placeholder="Enter session description"
            control={control}
            mt="md"
            disabled={!enableActions}
            autosize
            minRows={4}
            maxRows={8}
          />
          <FormSelect
            label="Location"
            name="locationId"
            control={control}
            placeholder="Select location"
            data={locationsOptions}
            mt="md"
            searchable
            nothingFoundMessage="No locations found. Please add a location first."
            withAsterisk
          />
          {event?.dates?.length > 0 ? (
            <>
              <DateTimePicker
                label="Starting"
                placeholder="Starting"
                name="starting"
                onChange={setStart}
                withAsterisk
                mt="md"
                clearable
                disabled={!enableActions}
                excludeDate={(data) => {
                  const allowedDates = event?.dates.map((date) => date.date);

                  return !allowedDates.includes(
                    data.toISOString().split("T")[0]
                  );
                }}
                hideOutsideDates
                control={control}
              />
              <DateTimePicker
                label="Ending"
                placeholder="Ending"
                name="ending"
                onChange={setEnd}
                withAsterisk
                mt="md"
                clearable
                disabled={!enableActions}
                excludeDate={(date) => {
                  const allowedDates = event?.dates.map((date) => date.date);

                  return (
                    !allowedDates.includes(date.toISOString().split("T")[0]) ||
                    (date < start &&
                      date.toDateString() !== start?.toDateString())
                  );
                }}
                control={control}
              />
            </>
          ) : (
            <Text mt="md">
              No event dates found. Please{" "}
              <Anchor component={Link} to="/app/events/customize">
                add event dates
              </Anchor>{" "}
              first to create a session.
            </Text>
          )}
          <MultiSelect
            checkIconPosition="left"
            data={speakersOptions}
            label="Speakers"
            name="speakers"
            placeholder="Add speaker"
            mt="md"
            searchable
            nothingFoundMessage="Nothing found"
            onChange={(value) =>
              setValue("speakers", value, { shouldDirty: true })
            }
            limit={10}
          />
          <Select
            label="Sponsor"
            name="sponsorId"
            control={control}
            placeholder="Add sponsor"
            data={sponsorsOptions}
            clearable
            searchable
            mt="md"
            onChange={(value) =>
              setValue("sponsorId", Number(value), { shouldDirty: true })
            }
          />

          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link title"
                  {...register(`links.${index}.title`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}

            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          {sessionTags && (
            <Fieldset legend="Tags" mt="md">
              {["dressCode", "sessionType", "topic"].map((type) => (
                <MultiSelect
                  key={type}
                  label={`Tags - ${formatTagLabel(type)}`}
                  name={`tags.${type}`}
                  placeholder={`Select ${formatTagLabel(type)} tags`}
                  data={tagOptions[type] || []}
                  mt="md"
                  searchable
                  nothingFoundMessage="No tags found"
                  clearable
                  onChange={(value) =>
                    setValue(`tags.${type}`, value, { shouldDirty: true })
                  }
                />
              ))}
            </Fieldset>
          )}

          {!enableActions && (
            <Text size="md" c="red" mt="md">
              Only system admins and workspace admins can create new sessions!
            </Text>
          )}

          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={!enableActions || isSuccess}
          >
            Create session
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
