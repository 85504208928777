export const getCurrentWorkspace = (user) => {
  const activeWorkspaceId = user?.config?.adminUI?.activeWorkspaceId;

  return (
    user?.config?.adminUI?.workspaces.find(
      (workspace) => workspace.id === activeWorkspaceId
    ) || user?.workspaces?.[0]
  );
};

const updateWorkspaceList = ({ workspaces, workspace, eventId = null }) => {
  return workspaces.some((ws) => ws.id === workspace.id)
    ? workspaces.map((ws) =>
        ws.id === workspace.id
          ? { ...ws, eventId: eventId !== null ? eventId : ws.eventId }
          : ws
      )
    : [...workspaces, { id: workspace.id, eventId }];
};

const updateConfig = ({
  workspace,
  user,
  setUser,
  mutate,
  eventId,
  config,
}) => {
  const updatedConfigWorkspaces = workspace
    ? updateWorkspaceList({
        workspaces: user.config?.adminUI?.workspaces || [],
        workspace,
        eventId,
      })
    : user.config?.adminUI?.workspaces || [];

  const updatedUserWorkspaces = workspace
    ? updateWorkspaceList({
        workspaces: user.workspaces || [],
        workspace,
      })
    : user.workspaces || [];

  const updateConfig = {
    ...user.config,
    adminUI: {
      ...user.config?.adminUI,
      workspaces: updatedConfigWorkspaces,
      activeWorkspaceId: workspace
        ? workspace.id
        : user.config?.adminUI?.activeWorkspaceId,
    },
    ...config,
  };

  setUser({ ...user, workspaces: updatedUserWorkspaces, config: updateConfig });
  mutate({ config: updateConfig });
};

export default updateConfig;
