import { useState } from "react";
import { Collapse, Group, rem } from "@mantine/core";
import { NavLink } from "react-router-dom";
import classes from "./Navbar.module.css";
import { IconChevronRight } from "@tabler/icons-react";

function NavbarLink({ item, disabled }) {
  const initiallyOpened =
    item?.links?.some((link) => link.path === window.location.pathname) ||
    false;

  const [opened, setOpened] = useState(initiallyOpened);
  const hasLinks = item.links && item.links.length > 0;

  const items = (hasLinks ? item.links : []).map((link) => (
    <NavLink
      to={link.path}
      key={link.label}
      className={({ isActive }) =>
        isActive ? `${classes.linkInner} ${classes.active}` : classes.linkInner
      }
      end
    >
      <span>{link.label}</span>
    </NavLink>
  ));

  if (disabled) {
    return (
      <div
        className={`${classes.link} ${classes.disabled}`}
        style={{ opacity: 0.5 }}
      >
        {item.icon && <item.icon stroke={1.5} className={classes.linkIcon} />}
        <span>{item.label}</span>
      </div>
    );
  }

  return (
    <>
      <Group justify="space-between" gap={0}>
        <NavLink
          to={item.path}
          key={item.label}
          className={({ isActive }) =>
            isActive ? `${classes.link} ${classes.active}` : classes.link
          }
          end
          onClick={() => setOpened((o) => !o)}
        >
          {item.icon && <item.icon stroke={1.5} className={classes.linkIcon} />}
          <span>{item.label}</span>
        </NavLink>
        {hasLinks && (
          <IconChevronRight
            className={classes.chevron}
            stroke={1.5}
            style={{
              width: rem(16),
              height: rem(16),
              transform: opened ? "rotate(-90deg)" : "none",
            }}
          />
        )}
      </Group>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

export default NavbarLink;
