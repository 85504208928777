import {
  Button,
  Group,
  Menu,
  ActionIcon,
  useMantineTheme,
  rem,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { Link, useLocation, useMatches } from "react-router-dom";
import classes from "./ActionButtons.module.css";

import {
  ensureNoTrailingSlashAtEndOfUrl,
  removeIdsFromUrl,
} from "../../services/helper";
import getActionButtonData from "./buttons";
import { useMediaQuery } from "@mantine/hooks";

const ActionButtons = () => {
  const theme = useMantineTheme();

  const matches = useMatches();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const actions = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.action))
    // then map them to crumb
    .flatMap((match) => match.handle?.action);

  if (actions.length === 0) return null;

  if (actions.length === 1) return <ActionButton action={actions[0]} />;

  if (!isMobile) {
    return (
      <div style={{ justifyItems: "flex-end" }}>
        {actions.map((action) => (
          <ActionButton key={action} action={action} ml="sm" />
        ))}
      </div>
    );
  }

  return (
    <Group wrap="nowrap" gap={0}>
      <ActionButton action={actions[0]} className={classes.button} />
      <Menu
        transitionProps={{ transition: "pop" }}
        position="bottom-end"
        withinPortal
        width={200}
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
          >
            <IconChevronDown
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {actions.map((action, index) => {
            if (index === 0) return null;

            const data = getActionButtonData(action);

            const locationPathname = removeIdsFromUrl(
              ensureNoTrailingSlashAtEndOfUrl(location.pathname)
            );

            return (
              <Menu.Item
                key={action}
                leftSection={
                  <data.icon
                    style={{ width: rem(24), height: rem(24) }}
                    stroke={1.5}
                    color={theme.colors.blue[5]}
                  />
                }
                component={Link}
                to={locationPathname + data.to}
              >
                {data.label}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

const ActionButton = ({ action, ...props }) => {
  const data = getActionButtonData(action);

  const location = useLocation();

  const locationPathname = removeIdsFromUrl(
    ensureNoTrailingSlashAtEndOfUrl(location.pathname)
  );

  return (
    <Button
      component={Link}
      to={locationPathname + data.to}
      leftSection={<data.icon />}
      {...props}
    >
      {data.label}
    </Button>
  );
};

export default ActionButtons;
