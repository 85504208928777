import {
  Button,
  Container,
  Flex,
  Group,
  Image as MantineImage,
} from "@mantine/core";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { modals } from "@mantine/modals";

const getCroppedImg = async (imageSrc, croppedArea, aspectRatio = 16 / 9) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const aspectWidth = image.width;
  const aspectHeight = aspectWidth / aspectRatio;

  canvas.width = aspectWidth;
  canvas.height = aspectHeight;

  ctx.drawImage(
    image,
    croppedArea.x,
    croppedArea.y,
    croppedArea.width,
    croppedArea.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "blob",
      0.7
    );
  });
};

const CropImage = ({
  imageSrc,
  imageName,
  setNewHeaderImage,
  aspectRatio = 16 / 9,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const onCropComplete = (_, croppedArea) => {
    setCroppedArea(croppedArea);
  };

  const handleSave = async () => {
    if (!croppedArea) return;

    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedArea,
        aspectRatio
      );

      const file = new File([croppedImage], imageName, {
        type: "image/jpeg",
      });

      setNewHeaderImage(file);
      modals.closeAll();
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handlePreview = async () => {
    // Close preview, if it's open
    if (previewImage) {
      setPreviewImage(null);
      return;
    }

    if (!croppedArea) return;

    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedArea,
        aspectRatio
      );

      const file = new File([croppedImage], imageName, {
        type: "image/jpeg",
      });

      setPreviewImage(URL.createObjectURL(file));
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <>
      <Group style={{ flexDirection: "row", gap: 5, justifyContent: "center" }}>
        <Container
          style={{
            width: 500,
            height: 281,
            position: "relative",
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Container>
        <Container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </Container>

        <Flex
          direction="column"
          style={{
            gap: 10,
            maxHeight: 300,
          }}
        >
          {previewImage && (
            <MantineImage
              src={previewImage}
              fit="fill"
              style={{ aspectRatio, border: "1px solid black" }}
              h={200}
              w="auto"
            />
          )}
          <Group gap={10}>
            <Button onClick={handlePreview}>
              {previewImage ? "Close" : "Preview"}
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Group>
        </Flex>
      </Group>
    </>
  );
};
export default CropImage;
