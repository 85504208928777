import { useState, useEffect } from "react";

export const useInlineTableEdit = (initialData = []) => {
  const [tableData, setTableData] = useState(initialData);
  // This stores the state of adding new rows
  const [isAddingRows, setIsAddingRows] = useState(false);
  // This will store the errors for each row and column
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTableData(initialData);
  }, [initialData]);

  // This will add new empty row to the table
  // when clicked on the add button
  const handleAddNewRow = (createEmptyRow) => {
    setIsAddingRows(true);
    const newRow = createEmptyRow();
    setTableData([newRow, ...tableData]);
  };

  // This will update the cell value in the table
  const handleCellChange = (id, column, value) => {
    const updatedData = tableData.map((row) =>
      row.id === id ? { ...row, [column]: value } : row
    );
    setTableData(updatedData);
  };

  // This will remove the row from the table
  // when clicked on the cancel button
  const cancelRowAdd = (id) => {
    setTableData((prev) => prev.filter((row) => row.id !== id));
    setIsAddingRows(false);
    setErrors({});
  };

  return {
    tableData,
    setTableData,
    isAddingRows,
    errors,
    setErrors,
    handleAddNewRow,
    handleCellChange,
    cancelRowAdd,
    onSave: () => setIsAddingRows(false),
  };
};
