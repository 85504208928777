import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Text } from "@mantine/core";
import { useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";

import { usePatchWorkspace } from "../../../api/workspace";

export default function EditForm({ data, isSysAdmin }) {
  const { id, name } = data;

  const { mutate, isSuccess, isPending: isLoading } = usePatchWorkspace({ id });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: data.name,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 1 },
      },
      required: ["name"],
    }),
  });

  return (
    <Container size={700}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(mutate)}>
          <TextInput
            label="Name"
            type="text"
            name="name"
            defaultValue={name}
            control={control}
            withAsterisk
            mt="md"
            disabled={!isSysAdmin}
          />
          {!isSysAdmin && (
            <Text size="md" c="red" mt="md">
              Only system admins can edit workspaces!
            </Text>
          )}
          <Button
            type="submit"
            mt="md"
            loading={isLoading}
            disabled={!isSysAdmin || !isDirty || isSuccess}
          >
            Edit workspace
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
