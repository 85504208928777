import { Flex, Image, Text } from "@mantine/core";

import logo from "../../../assets/logo-02.svg";
import classes from "./LoadingPage.module.css";

export default function LoadingPage() {
  return (
    <Flex align="center" justify="center" direction="column" h="100vh">
      <Image
        src={logo}
        alt="RoundCube logo"
        className={classes.imageOverlayAnimate}
        style={{ maxWidth: "250px" }}
      />
      <Text align="center" size="xl" weight={700}>
        Loading...
      </Text>
    </Flex>
  );
}
