import { Flex, Group, Button, Text } from "@mantine/core";
import { modals } from "@mantine/modals";

const moreThanOneAnnouncementModal = (showBothCallback, onlyThisCallback) => {
  return modals.open({
    title: "Are you sure?",
    children: (
      <Flex direction="column">
        <Text size="sm">
          Another announcement is already shown. Do you want to show this one as
          well?
        </Text>
        <Group justify="flex-end" gap="sm" mt={16}>
          <Button
            onClick={() => {
              modals.closeAll();
            }}
            variant="default"
          >
            Cancel
          </Button>
          <Button onClick={showBothCallback}>Show both</Button>
          <Button onClick={onlyThisCallback}>Show only this</Button>
        </Group>
      </Flex>
    ),
  });
};

export default moreThanOneAnnouncementModal;
