import { Container, Group, Paper, Progress, Text, Title } from "@mantine/core";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useGetPoll, useGetPollResults } from "../../api/polls";
import { AuthContext } from "../../services/context";

const PollResults = () => {
  const { id } = useParams();
  const pollId = id;
  const { user } = useContext(AuthContext);
  const isSysAdmin = user?.role === "sysAdmin";

  const location = useLocation();
  const isPresentationMode =
    location?.state?.isPresentationMode ||
    new RegExp(`^/app/polls/${pollId}/results/present$`).test(
      location.pathname
    );

  const { data: pollData } = useGetPoll(pollId);
  const { data: results, isLoading, error } = useGetPollResults(pollId);

  const voteCounts = useMemo(() => {
    const counts = {};

    if (results?.length > 0) {
      for (const result of results) {
        for (const ans of result.answer) {
          // Normalize the answer by removing punctuation and converting to lowercase
          const key = ans.text
            ? ans.text
                .toString()
                .toLowerCase()
                .replace(/[^a-z0-9]/g, "")
            : ans.id.toString();

          counts[key] = (counts[key] || 0) + 1;
        }
      }
    }

    return counts;
  }, [results]);

  if (!isSysAdmin) {
    return (
      <Container>
        <Text color="red">Only system admins can view poll results.</Text>
      </Container>
    );
  }

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text color="red">Error: {error.message}</Text>;
  }

  function renderResults(pollData) {
    if (pollData.type === "text") {
      return (
        <div
          style={{
            marginBottom: 20,
          }}
        >
          {Object.entries(voteCounts).map(([key, value]) => (
            <div key={key}>
              <Text>
                {key} - {value} {value === 1 ? "vote" : "votes"}
              </Text>
              <Text></Text>
            </div>
          ))}
        </div>
      );
    }
    return pollData?.options.map((option) => {
      const votesPercentage = (
        ((voteCounts[option.id] || 0) / results.length) *
        100
      ).toFixed(2);
      return (
        <div key={option.id}>
          <Text fw={500}>{option.title}</Text>
          <Group>
            <Text>
              <span>({voteCounts[option.id] || 0}</span> votes)
            </Text>
            <Text>{votesPercentage}%</Text>
          </Group>
          <Progress
            mt={5}
            mb={20}
            value={votesPercentage}
            max={100}
            color="blue"
            radius="xl"
          />
        </div>
      );
    });
  }

  return (
    <Container style={isPresentationMode && styles.container}>
      <Container
        style={{
          width: 500,
        }}
        my={20}
      >
        <Paper withBorder shadow="md" p="md">
          <Title size="lg" mb={20}>
            {pollData?.title}
          </Title>
          <Text>{pollData?.description}</Text>
          {Object.keys(voteCounts).length === 0 ? (
            <Text mt="md" mb={20}>
              No results available yet for this poll.
            </Text>
          ) : (
            renderResults(pollData)
          )}
          {!isPresentationMode && (
            <Link
              style={styles.link}
              state={{
                isPresentationMode: true,
              }}
              to={`/app/polls/${pollId}/results/present`}
            >
              Present results
            </Link>
          )}
        </Paper>
      </Container>
    </Container>
  );
};

export default PollResults;

const styles = {
  container: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  link: {
    backgroundColor: "#1554F6",
    color: "white",
    textDecoration: "none",
    padding: "10px 20px",
    borderRadius: 5,
  },
};
