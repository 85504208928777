import {
  Avatar,
  Button,
  Container,
  Fieldset,
  Group,
  Text,
  Title,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useGetCurrentUser } from "../api/user";
import LogOutButton from "./LogOutButton";
import RoleBadges from "./RoleBadges";

const Profile = () => {
  const { data } = useGetCurrentUser();

  return (
    <Container size="50%">
      <Fieldset>
        <Title order={2}>Profile</Title>
        <Group py="sm">
          <Avatar
            src={data?.picture}
            alt={data?.name}
            size="xl"
            radius="xl"
            style={{ marginRight: 20 }}
          >
            {data?.firstName[0]}
            {data?.lastName[0]}
          </Avatar>
          <div>
            <Text size="lg" fw={700}>
              {data?.firstName} {data?.lastName}
            </Text>
            <Text>{data?.occupation}</Text>
            <Text>{data?.email}</Text>
          </div>
        </Group>

        {data?.biography && (
          <>
            <Title order={5} style={{ marginBottom: 5, marginTop: 5 }}>
              Biography
            </Title>
            <Text>{data.biography}</Text>
          </>
        )}

        <Title order={5} style={{ marginBottom: 5, marginTop: 5 }}>
          Role
        </Title>
        <RoleBadges roleName={data?.role} />

        <Group
          py="sm"
          mt="md"
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <LogOutButton />
          <Button variant="light" component={Link} to="/app/profile/edit">
            Edit profile
          </Button>
        </Group>
      </Fieldset>
    </Container>
  );
};

export default Profile;
