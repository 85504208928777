/**
 * Renders a component for searching users and displaying them in a select dropdown.
 *
 * @component
 * @param {import("react-hook-form-mantine").SelectProps} props - The props object containing the component's properties.
 * @returns {JSX.Element} The rendered component.
 */
import { Loader, Text } from "@mantine/core";
import { Select } from "react-hook-form-mantine";
import { useState } from "react";

import { useGetUsers } from "../../../api/user";
import { useDebouncedValue } from "@mantine/hooks";

// TODO: Wanted to keep this as minimal as possible.
// Async search isn't working as expected, and I don't want to spend too much time on this.
// Implementing this in the future would be a good idea.
export default function SearchUsers({ ...props }) {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue] = useDebouncedValue(searchValue, 200);

  const {
    data: users,
    isPending,
    isError,
    error,
  } = useGetUsers({
    name: debouncedValue,
    page: 1,
    limit: 10,
    role: "sysAdmin,wsAdmin",
  });

  const options = users?.data?.map((user) => ({
    value: `${user.id}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  if (isError) return <Text c="red">{error.message}</Text>;

  return (
    <Select
      data={options}
      placeholder="Search users by name"
      searchable
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      rightSection={isPending ? <Loader size="1rem" /> : null}
      clearable
      nothingFoundMessage="No users found"
      {...props}
    />
  );
}
