import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";

import { useUpdateUser } from "../../api/user";

const DeleteProfilePicture = ({ userId, ...props }) => {
  const { mutate, isPending, isSuccess, isError, error } = useUpdateUser({
    id: userId,
  });

  const handleDelete = () => {
    mutate({ picture: null });
  };

  const openModal = () => {
    modals.openConfirmModal({
      title: "Delete profile picture",
      children: "Are you sure you want to delete your profile picture?",
      labels: { cancel: "Cancel", confirm: "Delete" },
      onConfirm: handleDelete,
    });
  };

  return (
    <Button
      loading={isPending}
      onClick={openModal}
      variant="light"
      color="red"
      {...props}
    >
      Delete profile picture
    </Button>
  );
};

export default DeleteProfilePicture;
