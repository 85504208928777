import { TextInput, Select } from "@mantine/core";

export const EditableCell = ({
  isExisting,
  value,
  onChange,
  error,
  inputProps = {},
  onSave,
  onCancel,
  type = "text",
  options = [],
  render,
}) => {
  if (isExisting) {
    // If the cell has a custom render function, use it
    if (render) {
      return render();
    }
    return value;
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      onSave?.();
    } else if (e.key === "Escape") {
      onCancel?.();
    }
  };

  if (type === "select") {
    return (
      <Select
        value={value}
        onChange={onChange}
        data={options}
        error={error}
        onKeyDown={handleKeyDown}
        {...inputProps}
      />
    );
  }

  return (
    <TextInput
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      onKeyDown={handleKeyDown}
      {...inputProps}
    />
  );
};
