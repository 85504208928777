import { useForm } from "react-hook-form";
import { Textarea, TextInput } from "react-hook-form-mantine";
import resolver from "../../../utils/resolver";

function LocationForm({ location, onSubmit, eventId, formId }) {
  const defaultValues = {
    eventId,
    name: location?.name || "",
    description: location?.description || "",
    picture: location?.picture || "",
  };

  const schema = {
    type: "object",
    properties: {
      name: { type: "string", minLength: 1 },
      description: { type: "string" },
      picture: { type: "string" },
      eventId: { type: "string" },
    },
    required: ["name", "eventId"],
  };

  const { control, handleSubmit: formSubmit } = useForm({
    defaultValues,
    resolver: resolver(schema, { coerceTypes: true }),
  });

  return (
    <form id={formId} onSubmit={formSubmit(onSubmit)}>
      <TextInput
        label="Location name"
        placeholder="Name"
        name="name"
        control={control}
        withAsterisk
      />

      <Textarea
        label="Description"
        placeholder="Description (e.g. the location is on the 3rd floor of the building, room 301)"
        name="description"
        rows={2}
        mt="sm"
        control={control}
        autosize
      />
    </form>
  );
}

export default LocationForm;
