import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetSponsors = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["sponsors", params] },
    fetchOptions: { url: "/sponsors", method: "GET", params },
  });

export const useGetSponsor = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["sponsor", { id }], enabled: !!id },
    fetchOptions: { url: `/sponsors/${id}`, method: "GET" },
  });

export const usePatchSponsor = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "patchSponsor" },
    fetchOptions: { url: `/sponsors/${id}`, method: "PATCH" },
    invalidateQueryKey: "sponsors",
    successMessage: { title: "Sponsor has been updated", color: "green" },
  });

export const useCreateSponsor = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateSponsor" },
    fetchOptions: {
      url: "/sponsors",
      method: "POST",
    },
    invalidateQueryKey: "sponsors",
    successMessage: { title: "Sponsor has been created", color: "green" },
  });

export const useDeleteSponsor = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteSponsor" },
    fetchOptions: { url: "/sponsors", method: "DELETE" },
    invalidateQueryKey: "sponsors",
    successMessage: { title: "Sponsor has been deleted", color: "green" },
  });

export const useGetSponsorLevels = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["sponsorLevels"], enabled: !!params.eventId },
    fetchOptions: { url: `/sponsors/levels`, method: "GET", params },
  });

export const usePatchSponsorLevels = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "patchSponsorLevel" },
    fetchOptions: { url: `/sponsors/levels`, method: "POST" },
    invalidateQueryKey: "sponsorLevels",
    successMessage: {
      title: "Sponsor levels have been updated",
      color: "green",
    },
  });

export const useGetSponsorReps = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["sponsorReps"], enabled: !!params.eventId },
    fetchOptions: { url: `/sponsors/reps`, method: "GET", params },
  });
