import { useApiMutation, useApiQuery } from "../services/react-query";

export const useLogin = () =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"], retry: 0 },
    fetchOptions: {
      url: "/auth/login",
      method: "POST",
    },
    successMessage: { title: "Login successful", color: "green" },
  });

export const useSendInviteMail = (userPublicId) =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: `/auth/invite/${userPublicId}`,
      method: "POST",
    },
  });

export const useGetUserByInviteToken = (token) =>
  useApiQuery({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: `/auth/${token}`,
      method: "GET",
    },
  });

export const useSetPassword = () =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: "/auth/set-password",
      method: "POST",
    },
  });

export const useGetUserByForgotPasswordToken = (token) =>
  useApiQuery({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: `/auth/forgot-password/${token}`,
      method: "GET",
    },
  });

export const useGoogleLogin = (data) =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: "/auth/google-sign-in",
      method: "POST",
      body: { data },
    },
  });

export const useAppleSignIn = (data) =>
  useApiMutation({
    queryOptions: { queryKey: ["auth"] },
    fetchOptions: {
      url: "/auth/apple-sign-in",
      method: "POST",
      body: { data },
    },
  });
