import { modals } from "@mantine/modals";
import { Badge, Button, Text, ColorInput, DEFAULT_THEME } from "@mantine/core";
import { useState } from "react";

import { useUpdateTagColor } from "../../api/tag";
import { addSpacesToCamelCase } from "./utils/addSpacesToCamelCase";

function ColorModal({ item, eventId }) {
  const [color, setColor] = useState(item.color);
  const { mutate, isPending, isError, error, isSuccess } = useUpdateTagColor();

  const getLimitedSwatches = (colorArray, limit) => {
    return colorArray.slice(-limit);
  };

  const swatchesLimit = 5;
  const colorKeys = ["yellow", "red", "blue", "green", "violet", "cyan"];

  const limitedSwatches = colorKeys.flatMap((colorKey) =>
    getLimitedSwatches(DEFAULT_THEME.colors[colorKey], swatchesLimit)
  );

  const handleSubmit = () => {
    mutate({ subtype: item.subtype, color, eventId: Number(eventId) });
  };

  return (
    <>
      <ColorInput
        my={10}
        value={color}
        withEyeDropper={false}
        withPicker={false}
        swatches={limitedSwatches}
        swatchesPerRow={10}
        closeOnColorSwatchClick
        onChange={setColor}
      />
      <Button onClick={handleSubmit} loading={isPending} disabled={isSuccess}>
        Confirm color
      </Button>

      {isError && (
        <Text c="red">
          {error.message.includes("Duplicate entry")
            ? "This color is already appointed to another subtype. Please choose another color."
            : error.message}
        </Text>
      )}
    </>
  );
}

export function openColorModal({ item, currentEventId }) {
  modals.open({
    title: (
      <div>
        Select color for tag:
        <Badge color={item.color} my={10} variant="light">
          {addSpacesToCamelCase(item.subtype)}
        </Badge>
      </div>
    ),
    children: <ColorModal item={item} eventId={currentEventId} />,
    size: "md",
    centered: true,
    withCloseButton: true,
  });
}

export default openColorModal;
