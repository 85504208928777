import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetUsers = (params) =>
  useApiQuery({
    queryOptions: { queryKey: ["users", params] },
    fetchOptions: {
      url: "/users",
      method: "GET",
      params,
    },
  });

export const useGetUser = (id) =>
  useApiQuery({
    queryOptions: { queryKey: ["user", { id }], enabled: !!id },
    fetchOptions: { url: `/users/${id}`, method: "GET" },
  });

export const useGetCurrentUser = () =>
  useApiQuery({
    queryOptions: { queryKey: ["currentUser"] },
    fetchOptions: { url: "/user/self", method: "GET" },
  });

export const useUpdateCurrentUser = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useUpdateCurrentUser" },
    fetchOptions: { url: "/user/self", method: "PATCH" },
    invalidateQueryKey: "currentUser",
    successMessage: { title: "Your profile has been updated", color: "green" },
  });

export const useCreateUser = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useCreateUser" },
    fetchOptions: { url: "/users", method: "POST" },
    invalidateQueryKey: "users",
    successMessage: { title: "User has been created", color: "green" },
  });

export const useUpdateUser = ({ id, config }) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useUpdateUser" },
    fetchOptions: { url: `/users/${id}`, method: "PATCH" },
    invalidateQueryKey: ["user", { id }],
    ...config,
    successMessage: { title: "User has been updated", color: "green" },
  });

export const useDeleteUser = (id) =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteUser" },
    fetchOptions: { url: `/users/${id}`, method: "DELETE" },
    invalidateQueryKey: "users",
    successMessage: { title: "User has been deleted", color: "green" },
  });
