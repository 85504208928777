import { ActionIcon } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";

import { useDeleteUser } from "../../api/user";

export default function DeleteUserModal({ userId }) {
  // No idea on how to implement confirm message here.
  // I need ideas, please. Should we implement notifications?
  const { mutate } = useDeleteUser(userId);

  const openModal = () =>
    modals.openConfirmModal({
      title: "Delete user",
      children:
        "Are you sure you want to delete this user? This action cannot be undone.",
      labels: { confirm: "Remove", cancel: "Cancel" },
      onConfirm: () => {
        mutate();
      },
    });

  return (
    <ActionIcon
      onClick={openModal}
      aria-label="Delete user"
      color="red"
      variant="light"
    >
      <IconTrash />
    </ActionIcon>
  );
}
