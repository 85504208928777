import { useApiMutation } from "../services/react-query";

export const useUpload = () =>
  useApiMutation({
    queryOptions: { queryKey: ["r2"] },
    fetchOptions: {
      url: "/r2/upload",
      method: "PUT",
    },
  });
