import { Loader, Text } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetTagById } from "../../../api/tag";
import { AuthContext } from "../../../services/context";
import EditForm from "./Form";

export default function EditSession() {
  const { tagId } = useParams();
  const { user } = useContext(AuthContext);

  const enableActions = user.role === "sysAdmin" || user.role === "wsAdmin";
  const {
    data: responseData,
    isPending,
    isError,
    error,
  } = useGetTagById(tagId);

  if (isPending) {
    return <Loader mt={10} />;
  }

  if (isError) {
    return <Text c="red">{error.message}</Text>;
  }

  return <EditForm data={responseData} enableActions={enableActions} />;
}
