import {
  ActionIcon,
  Card,
  Container,
  Flex,
  Group,
  Loader,
  Title,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";

export default function EventsCards({ data, isLoading, isSysAdmin }) {
  return isLoading ? (
    <Flex justify="center">
      <Loader m={10} />
    </Flex>
  ) : (
    <Container mt="md">
      {data?.data.map((event) => (
        <Card shadow="sm" p="lg" radius="md" withBorder mb={5} key={event.id}>
          <Flex direction="row" justify="space-between">
            <Title order={5}>{event.name}</Title>
            <Group>
              <ActionIcon size="md">
                <IconEdit />
              </ActionIcon>
              <ActionIcon size="md" disabled={!isSysAdmin}>
                <IconTrash />
              </ActionIcon>
            </Group>
          </Flex>
        </Card>
      ))}
    </Container>
  );
}
