import { useState } from "react";
import { ActionIcon, Group, Text, Tooltip, Badge } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconActivity, IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { DataTable } from "../DataTable";
import { useDeletePoll } from "../../api/polls";
import { EvaIcon } from "./../EvaIcon";

export default function PollsTable({ data, isLoading, role, page, setPage }) {
  const [selectedPollId, setSelectedPollId] = useState(null);
  const { mutate } = useDeletePoll(selectedPollId);

  const deletePollById = (id) => {
    setSelectedPollId(id);
    modals.openConfirmModal({
      title: "Are you sure you want to delete poll?",
      labels: { confirm: "Yes", cancel: "Cancel" },
      onConfirm: () => mutate(id),
    });
  };
  return (
    <DataTable
      records={data?.data}
      columns={[
        { accessor: "id" },
        {
          accessor: "sessionTitle",
        },
        {
          accessor: "title",
          title: "Question",
          render: (data) => (
            <div style={styles.wrapper}>
              <Text size="sm">{data?.title}</Text>
              {data?.isVisible && (
                <Badge variant="dot" color="green">
                  Visible
                </Badge>
              )}
            </div>
          ),
        },
        { accessor: "description" },
        {
          accessor: "status",
          render: (data) => (
            <Badge
              variant="light"
              color={data?.status === "open" ? "green" : "red"}
            >
              {data?.status}
            </Badge>
          ),
        },
        {
          accessor: "areResultsVisible",
          title: (
            <div
              style={{
                ...styles.wrapper,
                gap: 2,
              }}
            >
              Results
              <Tooltip
                multiline
                w={220}
                label="After users submit their vote, they are able to see (in Shared results) or not able to see (in Hidden results) the results for that Poll"
              >
                <div
                  style={{
                    height: 18,
                    width: 18,
                  }}
                >
                  <EvaIcon name="info-outline" />
                </div>
              </Tooltip>
            </div>
          ),
          render: (data) => (
            <>
              <Badge
                variant={data?.areResultsVisible ? "light" : "outline"}
                color={data?.areResultsVisible ? "orange" : "gray"}
              >
                {data?.areResultsVisible ? "Shared" : "Hidden"}
              </Badge>
            </>
          ),
        },
        {
          accessor: "actions",
          width: 150,
          render: (poll) => (
            <Group justify="center">
              <Tooltip label="Edit">
                <ActionIcon
                  size="md"
                  variant="light"
                  component={Link}
                  to={`/app/polls/${poll.id}/edit`}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete" disabled>
                <ActionIcon
                  size="md"
                  variant="light"
                  color="red"
                  onClick={() => deletePollById(poll.id)}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Results">
                <ActionIcon
                  size="md"
                  variant="light"
                  color="green"
                  component={Link}
                  to={`/app/polls/${poll.id}/results`}
                >
                  <IconActivity />
                </ActionIcon>
              </Tooltip>
            </Group>
          ),
        },
      ]}
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
    />
  );
}

const styles = {
  wrapper: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
};
