import { Container } from "@mantine/core";
import { Navigate } from "react-router-dom";

export default function Home() {
  return (
    <Container>
      <Navigate to="/app/workspaces" />;
    </Container>
  );
}
