import { z } from "zod";

export const createTagSchema = z.object({
  type: z.enum(["people", "session"]),
  subtype: z.enum([
    "categoryOfUser",
    "topicOfInterest",
    "dressCode",
    "sessionType",
    "topic",
  ]),
  title: z.array(z.string().min(2)),
  categoryOfUser: z.array(z.string().min(2)),
});
