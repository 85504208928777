import {
  ActionIcon,
  Card,
  Container,
  Flex,
  Group,
  Loader,
  Title,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";

export default function SponsorsCard({ data, isLoading }) {
  return isLoading ? (
    <Flex justify="center">
      <Loader m={10} />
    </Flex>
  ) : (
    <Container mt="md">
      {data?.data.map((sponsor) => (
        <Card shadow="sm" p="lg" radius="md" withBorder mb={5} key={sponsor.id}>
          <Flex direction="row" justify="space-between">
            <Title order={5}>{sponsor.name}</Title>
            <Title order={5}>{sponsor.subtitle}</Title>
            <Title order={6}>{sponsor.description}</Title>
            <Group>
              <Title>{sponsor.links.label}</Title>
              <Title>{sponsor.links.url}</Title>
            </Group>
            <Group>
              <ActionIcon size="md">
                <IconEdit />
              </ActionIcon>
              <ActionIcon size="md">
                <IconTrash />
              </ActionIcon>
            </Group>
          </Flex>
        </Card>
      ))}
    </Container>
  );
}
